@import './variables';
.popContainer {
    position: absolute;
    top: 7%;
    background-color: $back;
    border: 2px solid var(--common-text);
    border-radius: 15px;
    width: max-content;
    height: fit-content;

    &.bottom {
        top: 75%;
        margin-left: 0;

    }

    &.active {
        border-color: $green;
    }

    .popText {
        text-align: center;
        padding: 5px 10px;
        font-size: 15px;
        line-height: 21px;
        width: max-content;
    }

    .arrow {
        position: absolute;
        top: -20%;
        left: 45%;
        width: 0;
        height: 0;
        border: solid var(--common-text);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        background-color: $back;

        &.up {
            transform: rotate(-135deg);
        }
        &.down {
            transform: rotate(45deg);
            top: 95%;
        }
        &.active {
            border-color: $green;
        }
    }
}
