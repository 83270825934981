$mobile: 'max-width: 768px';

.quiz-skeleton {

    &__title {
        display: flex;
        margin-left: 30px;
        @media ($mobile) {
            display: block;
        }

        &--attemps {
            width: 30% !important;
            margin-left: auto;
            margin-right: 0;
            padding: 0 !important;
            @media ($mobile) {
                width: 100% !important;
                padding-right: 20px !important;
            }
        }
    }

    &__body{
        background: var(--background);
        padding: 20px;
        border-radius: 5px;
    }
}