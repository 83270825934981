.main-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    padding-left: 0;
    // background-color: var(--white);
    height: 70px;
    border-radius: 8px;
    margin-bottom: 16px;
    margin-top: 24px;
    // border: 1px solid var(--light-gray);
    &__title {
        font-weight: bold;
        font-size: 22px;
        color: var(--common-text);
    }
}
