@import '../Quiz/Question/questionCommons';

.quiz-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px auto;
    
    &__title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
 
        &__icon {
            margin-right: 10px;
            width: 24px;
        }

        &__title {
            font-size: 18px;
            font-weight: 600;
        }

        @media screen and (max-width: 730px) {
            margin: 16px 8px;
        }
    }

    &__attempts {
        color: $quiz-font-color;
        font-family: var(--primary-font);
        font-size: 15px;
    }

    @media screen and (max-width: 730px) {
        flex-direction: column;
    }
}

.quiz-block-name-container{
    margin-top: 20px;
    margin-bottom: 40px;
    color: $quiz-font-color;

    &__title-container {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 8px;
    }

    &__description-container {
        font-size: 16px;
        margin: 8px 42px 0 0;
    }

    &__separator-container {
        background-color: $order-background;
        height: 21px;
        margin-top: 23px;

        &__botttom-block {
            height: 13px;
            background-color: $order-background;
        }
    }

}