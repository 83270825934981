.modal-panel {
    background-color: var(--background);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 20px 15px 25px;
    border-radius: 12px;
    margin-bottom: 8px;
    min-height: 70px;

    &--no-theme {
        background-color: unset;
        border-radius: unset;
        padding: 0;
        
    }
    
    .message-box {
        width: 100%;
    }

    &__label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        width: max-content;
        white-space: nowrap;
    }

    &__input {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        min-height: 40px;
        padding-left: 20px;
        &:first-child {
            padding-left: 0;
        }
        &.full-width {
            width: 100%;
        }
        .legend {
            display: flex;
            &__icon {
                align-items: center;
                padding-bottom: 2.5px;
                padding-left: 5px;
            }
        }
    }

    &__toggle-input {
        display: flex;
        padding-top: 20px;

        .tooltip-icon {
            margin-left: 5px;
            position: relative;
            top: 2px;
        }
    }

    &__number-input {
        .input-number {
            width: max-content;
        }
    }
}
