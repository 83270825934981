$black-text: var(--common-text);
$gray-semi-dark: var(--disabled);
$turquoise: var(--primary-light);
$hover-blue: var(--primary-dark);

$bg-card: var(--white);
$border-card: var(--light-gray);
$dark-card-shadow: rgba(var(--rgb-black), 0.11);

$opacity-lighter-gray: var(--background);
$opacity-gray: var(--light-gray);
$opacity-darker-gray: var(--light-gray);

$opaque-green: var(--success-light);
