.table-general-grade {
    display: flex;
    align-items: center;
    &--success {
        color: var(--success);
    }
    &--error {
        color: var(--danger);
    }
    &--warn {
        color: var(--warning);
    }
    &__text {
        font-family: var(--primary-font);
        font-weight: bold;
        text-transform: uppercase;
        margin-left: 8px;
    }
}
