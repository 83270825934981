.dashboard-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__titles {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        width: 75%;

        &__course {
            display: flex;
            flex-direction: row;
            align-items: center;

            &__alias {
                font-family: var(--secondary-font);
                font-weight: 600;
                line-height: 33px;
                font-size: 22px;
                color: var(--common-text);
                padding-right: 18px;
            }

            &__name {
                line-height: 18.86px;
                font-weight: 400;
                font-family: var(--primary-font);
                font-size: 15px;
                color: var(--common-text);
            }
        }
    }

    &__select {
        width: 345px;
        margin-top: 20px;
    }
}

@media (max-width: 900px) {
    .dashboard-header {
        flex-direction: column;
        align-items: start;

        &__titles__course {
            flex-direction: column;
            align-items: start;
        }

        &__select {
            margin-top: 15px;
            width: 345px;
        }
    }
}
