.confirmDeleteMessage {
    font-family: var(--primary-font);
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    color: var(--common-text);
    margin: auto;
    word-break: break-word;
}

.tools-modal-body {
    padding-bottom: 10px !important;

    &__label {
        display: flex;
        align-items: center;

        &--tooltip {
            margin-left: 8px;
        }

        &-counter {
            margin-bottom: 6px;
            margin-left: auto;
        }
    }

    &__input {
        margin-bottom: 20px;
    }
}

.tools-modal-buttons {
    margin-bottom: 0 !important;
}
