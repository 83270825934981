.teacher-feedback-form {
    border: solid thin var(--light-gray);
    padding: 18px 23px;
    border-radius: 6px;
    background-color: var(--white);
    margin-top: 2rem;

    // .note-statusbar {
    //     display: none;
    // }

    &__feedback {
        font-size: 16px;
        font-family: var(--primary-font);
        color: var(--common-text);
        font-weight: 600;
    }

    &__summernote-container {
        position: relative;
        padding-top: 2.5rem;
        margin-bottom: 20px;

        .note-editing-area {
            background-color: var(--white);
            opacity: 0.5;
        }
    }

    .summernote-limit-counter {
        position: absolute;
        right: 5px;
        top: 0;
    }
}