$green: var(--success);
$alphaGray: var(--light-gray);

.bar{
    width: 142px;
    height: 4px;
    border-radius: 10px;
    background-color: $alphaGray;

    &.__completed {
        background-color: $green;
    }

}
