.animateComponent {
  visibility: hidden;
  opacity: 0 !important;
  transition: all 0.2s ease-out;

  &.show {
    visibility: visible;
    opacity: 1 !important;
    transition: all 0.2s ease-in !important;
  }
}
