.__cardRadioButtonBox {
    display: inline-flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;

    .__cardRadioButtonsGroup {
        display: inline-flex;
        flex-direction: column;
        padding: 0;

        & > li {
            list-style: none;
            border: solid 1px var(--light-gray);
            border-radius: 10px;
            padding: 23px 17px 22px 9px;
            height: 42px;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 5px 0;
            justify-content: space-between;

            ._cardCol_1 {
                flex-direction: row;
                align-items: center;
                display: flex;

                ._cardRadioBtn_number {
                    list-style: none;
                    border: solid 1px var(--light-gray);
                    border-radius: 7px;
                    padding: 6px 11px 4px 11px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                ._cardRadioBtn_option {
                    margin-left: 18px;
                    margin-top: 2px;
                }
            }

            ._cardCol_2 {
                ._cardRadioBtn_check {
                    font-size: 15px;
                }
            }
        }
    }

    @media (orientation: portrait) {
        margin-top: 5px;
    }
}
