.quiz-nav {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex: 1;
    margin: 39px -25px 48px -25px;

    &__button-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &__button {
            width: 24px;
            height: 24px;
            padding: 1px;
            margin: 8px;
            border-radius: 50%;
            border-radius: 50%;

            &--selected {
                box-shadow: 0 0 0 1px var(--common-text);
                background-color: var(--background);
            }

            &--not-empty{
                background-color:var(--background);
            }

        }

        &__button-indicator{
            height: 20px;
            width: 20px;
            color: var(--danger);
            left: 10px;
            align-self: center;
            text-align: center;
            position: relative;

            &--correct{
                color: var(--success);
            }
        }

    }
    
    &__nav-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    &__button-nav {
        margin: 0 15px 0 15px;
        padding-top: 10px;

        &--right{
            margin-left: -10px;
        }
    }

    &__text-button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        padding: 0 0 0 0;
        color: var(--primary);
        font-family: var(--secondary-font);
        font-size: 15px;

        @media screen and (max-width: 730px) {
            justify-content: space-between;
            margin-bottom: 40px;
        }
    }

    @media screen and (max-width: 730px) {
        margin: 32px 0 40px 0px;
    }
}