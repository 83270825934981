@mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

.lesson-map-container {
    border-bottom: 1px solid var(--light-gray);
    padding: 20px;
    list-style-type: none;

    &__title {
        font-family: var(--primary-font);
        padding-left: 0px;

        &__box {
            display: flex;
        }

        &__button-text {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            vertical-align: middle;
            width: 90%;
            height: 100% !important;

            &__class-icon {
                color: var(--common-text);
                margin-right: 16px;
                left: 0;
            }

            &__class-name {
                color: var(--common-text);
                font-weight: bold;
                cursor: pointer;
                font-size: 16px;
                text-align: left;
                vertical-align: top;
            }

            &__not-released {
                vertical-align: baseline;
                width: 18px;
            }

            // @TODO Reemplazar <Tooltip ... /> por <Overlay theme='tooltip' ... /> y borrar esta clase
            .tooltip-wrapper {
                width: initial;

                [id^='LessonIconTooltip'] {
                    left: 32px !important;
                }
            }
        }
    }

    &__topics-container {
        padding: 0;
        padding-left: 16px;
        overflow: hidden;
        @include transition(max-height 400ms cubic-bezier(1, 0, 0, 1));
    }

    &__icon-container__icon {
        @include transition(transform 250ms cubic-bezier(1, 0, 0, 1));
        width: 20px;
        height: 20px;
    }

    &--collapse {
        .lesson-map-container {
            &__topics-container {
                max-height: 0;
                margin: 0;
            }
            &__icon-container__icon {
                transform: rotate(-180deg);
            }
        }
    }

    &:last-child {
        border-bottom: none;
    }
}
