.block-issue-menu {
    margin-top: -20px;

    &__dropdown {
        background: var(--white);
        border-radius: 10px;
        position: absolute;
        right: 0;
        min-width: 150px;
        margin-right: 10px;
        z-index: 1002;
        box-shadow: var(--common-box-shadow);
        font-family: var(--primary-font);

        &--icon {
            position: relative;
            top: 2px;
        }
    }

    &__dropdown--item {
        display: flex;
        justify-content: flex-start;
        background: transparent;
        border: none;
        align-items: center;
        width: 100%;
        padding: 8px 10px;
        font-size: 15px;
        font-weight: 400;
    }

    &__list {
        float: right;
        position: relative;
        margin-right: -20px;
    }
}
