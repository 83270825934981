.cover-detail {
  &__container {
    display: inline-block;
    color: var(--common-text);
    border-radius: 5px;
    background-color: var(--primary-light);
  
    @media screen and (max-width: 730px) {
      margin: 12px 0 12px 0;
    }
  }
  
  &__attempts {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 5px 0 0 5px;
    background-color: rgb(67, 73, 181, 0.3);
  }
  
  &__questions {
    display: inline-block;
    padding: 4px 8px;
  
    &_only {
      background-color: rgb(67, 73, 181, 0.3);
      border-radius: 5px;
    }
  }
}
