.general-feedback{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--background);
    width: 100%;
    margin: 41px 0 27px 0;
    padding: 13px 27px 13px 19px;
    border: solid 2px var(--success-light);
    &--wrong{
        border: solid 2px var(--warning-light);
    }

    &__icon{
        font-size: 18px;
        color: var(--success);
        padding-top: 2px;
        padding-left: 8px;
        &--wrong{
            color: var(--warning);
            font-size: 20px;
        }

        .icon__box--inline{
            height: 12px;
        } 
    }

    &__text{
        margin-left: 13px;
        font-family: var(--primary-font);
        font-size: 15px;
        color: var(--disabled);
    }

    @media screen and (max-width: 730px) {

    }
}
