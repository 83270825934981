.grades-modal {
    &__icon {
        margin-right: 14px;
    }
    &__info {
        margin: 0 auto;
        margin-bottom: 25px;
    }
    &__send-message {
        margin: 0 auto;
        margin-top: 25px;
    }
    &__title {
        display: flex;
        align-items: center;
        text-transform: initial;
        font-size: 18px;
        font-weight: 600;
    }
    &__message {
        margin-bottom: 14px;
        text-align: left;
    }
    &__link {
        color: var(--primary);
        text-decoration: underline;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0;
    }
    &__body-footer {
        display: flex;
        justify-content: space-between;
    }
    &__file-uploader {
        display: flex;
        justify-content: stretch;
        height: 193px;

        &--limit {
            height: unset;
        }
    }
}
