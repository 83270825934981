.empty-state-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7.5vh;

    &__content {
        margin: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;

        &-text {
            text-align: center;
            font-family: var(--secondary-font);
            color: var(--disabled);
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 4rem;
        }

        &-default-img {
            margin: 0 auto;
        }
    }
}
.empty-selection-dashboard {
    .empty-state-wrapper-default-img {
        width: 300px;
    }
}
.empty-progress-dashboard {
    .empty-state-wrapper-default-img {
        width: 125px;
    }
}
