.RotatePhoneMessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  top: -8px;

  .messageIcon_box {
    display: block;
    margin: 3px 13px 0 0;
    
    .messageIcon {
        color: var(--disabled);
        font-size: 40px;
      }
  }

  p {
    display:block;
    color: var(--disabled);
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    width: 90%;
  }
}
