.__dropdown-submenu {
    position: absolute;
    outline: none;
    border: 1.3px solid var(--primary-light);
    border-radius: 7px;
    top: calc(100% + 15px);
    background: var(--white);
    padding: 0;
    white-space: nowrap;
    z-index: 1080;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    color: var(--primary);
    min-width: 100%;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 14px;

    li {
        padding: 8px 20px;
        list-style: none;
        transition: 0.2s;

        &:first-child {
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
        }

        &:last-child {
            border-bottom-right-radius: 7px;
            border-bottom-left-radius: 7px;
        }

        &:hover {
            background: var(--background);
        }
    }
}
