@import './variables';

#onboardingNavPopover {
    background-color: $ph-darkblue !important;
    border-radius: 12px !important;
    max-width: 325px !important;
    padding: 0 !important;

}
.popover.bottom > .arrow:after {
    border-bottom-color: $ph-darkblue !important;
}

#onboardingNavPopover .onboardingPopoverBody .paragraph {
    font-family: var(--primary-font);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color:var(--white);
    padding-left: 16px;
    padding-right: 26px;
    padding-top: 0;
}

._btn_group.popoverConfirm {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 12px;
}

._btn_group.popoverConfirm Button {
    &:hover{
        box-shadow: none;
    }
}

.onboardingPopoverHeader {
    display: flex;
    flex-direction: row-reverse;
}

.onboardingPopoverFooter { 
    padding-bottom: 0;
    margin-bottom: 16px;
}

Button.close_icon {
    color: var(--primary-light);
    font-size: 22px;
    font-weight: bold;
    align-self: baseline;
    border: none;
    background-color: transparent;
    text-shadow: 0 1px 0 var(--white);
    position: relative;
    top: -3px;

    path {
        stroke: $ph-darkblue;
        stroke-width: 45px;
    }

    &:hover {
        color:var(--white);
        text-decoration: none;
        cursor: pointer;
    }
    &:focus {
        outline: unset;
    }
}

.backCinema {
    position: fixed;
    top:0px;
    z-index: 1001;
    left: 0px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color:var(--common-text);
    opacity: 0.5;
}

.overCinema {
    position: relative;
    z-index: 1002;
    overflow: hidden;
    border-radius: 10px;
    background-color:var(--white);
    height: 50px;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.overCinema__container {
    margin-left: 15px;
}

.overCinema a {
    text-decoration: none !important;
}

.progressPanel .__left_sticky{
    position: relative !important;
    top: 0px !important;
    z-index: 1000 !important;
}
