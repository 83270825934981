@import '../../../classroomCommons';

.basic-block-content {
    display: flex;
    flex-direction: column;
    overflow-wrap: anywhere;
    max-width: 100%;
    overflow-x: hidden;
    background: var(--white);
    padding: 30px 20px;
    border-radius: 7px;
    border: 1px solid var(--light-gray);
    
    &:not(:first-child) {
        margin: 10px 0 0;
    }

    &:last-child {
        margin-bottom: 10px;
    }

    &__content {
        margin: 0 auto;

        // min-height: 50vh;

        @media screen and (min-width: $desktop-break-point) {
            min-height: 30vh;
        }
    }

    &__blockHeader {
        font-family: var(--primary-font);
        font-size: 22px;
        font-weight: 600;
        color: var(--common-text);
        margin-bottom: 10px;

        font-family: var(--secondary-font);
        margin-bottom: 24px;
        margin-top: 0;
        font-size: 22px;
        font-weight: 500;

    }

    &__rotate-phone {
        justify-content: center;
        align-items: center;
        top: 0;
        padding-top: 18px;
    }
}
