.unitSlider .slick-disabled {
    display:none;
}

.slick-track {
    margin: 0 auto;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.unitSlider {
    background-color:var(--white);
    padding: 10px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;
    margin-bottom: 72px;
}
.unitSlider .unitBox{
    width: 106px;
    height: 97px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    overflow: hidden;
    display: inline-block;
}

 .unitBox .unitBoxTitle {
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 5px;
    color: var(--primary);
    font-family: var(--primary-font);
    text-align: center;
}

.unitSlider .unitBox p {
    padding-right: 6px;
    padding-left: 6px;
    font-family: var(--primary-font);
    font-weight: 600;
    color: var(--common-text);
    font-size: 12px;
    line-height: 15px;
    text-align: center;

}

.unitSlider .unitSeparator {
    height: 97px;
    display:inline-block;
}

.unitSlider .unitSeparator .separatorLine {
    border-bottom: 1px solid var(--primary);
    width: 12px;
    margin: 0 auto;
    padding-bottom: 48px;
    height: 0;
}

.unitSlider .unitSeparator .startShape {
    padding-top: 39px;
    padding-left: 21px;
}

.unitSlider .unitSeparator .startShape.finish {
    padding-left: 5px;
}

.unitSlider .unitSeparator .extremeDot {
    background-color: var(--primary);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
}
.unitSlider .unitSeparator .startLine {
    color: var(--primary);
    letter-spacing: -2px;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 0;
}
.unitSlider .unitSeparator .dateText {
    font-size: 12px;
    color: var(--common-text);
    text-align: center;
    font-style: oblique;
    padding-right: 25px;
}

.unitSlider .unitSeparator .dateText.finish {
    padding-right: 0;
}
