.evaluable-block {
    &:not(:first-child) {
        margin: 10px 0 0;
    }

    &:last-child{
        margin-bottom: 10px;
    }
    .icon__box--inline {
        display: inline-flex;
    }
    
    &__statement {
        margin-top: 16px;
    }

    &__goto-link {
        margin-top: 16px;
    }

    &__files {
        margin-top: 16px;
    }

    &__activity {
        border: 1px solid var(--light-gray);
        border-radius: 6px;
        padding-left: 21px;
        padding-right: 21px;
        padding-top: 21px;
        padding-bottom: 28px;
        margin-top: 16px;

        &--disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &--subtitle {
            font-family: var(--primary-font);
            font-weight: 600;
            font-size: 16px;
            line-height: 11.6px;
        }

        &__link {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;

            .paste {
                display: flex;
                flex-direction: row;
                align-items: center;
                &__text {
                    padding: 0 15px;
                    margin: 0;
                    white-space: nowrap;
                    width: max-content;
                    font-family: var(--primary-font);
                    font-size: 16px;
                    color: var(--common-text);
                    font-weight: 400;
                }
            }

            @media (orientation: portrait) {
                flex-direction: column;
                align-items: flex-start;
                .paste {
                    margin-bottom: 16px;
                }
            }
        }

        &__text-area {
            margin-top: 16px;
            h4 {
                margin-bottom: 0;
            }
            &__chars {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }
            &__input {
                height: 179px;
                &--external {
                    height: 114px;

                }
                @media (orientation: portrait) {
                    height: 182px;
                }
            }
        }

        &__status {
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &--button {
                margin-left: auto;
            }

            &--text {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-family: var(--secondary-font);
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                
                .status-icon {
                    margin-right: 2px;
                    cursor: default;
                    padding-bottom: 3px;
                }

                &--idle {
                    color: var(--disabled);
                }

                &--pending {
                    color: var(--info);
                }
            }

            @media (orientation: portrait) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                
                &--button {
                    margin: 0;
                    margin-top: 10px;
                }
            }
        }
        &__feedback {
            margin-top: 16px;
            &__files {
                margin-top: 16px;
            }

            &--inner-html,
            &--inner-html > * {
                word-wrap: break-word;
                overflow-wrap: anywhere;
            }
        }
    }
}
