.__url_cover_title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.__cover_label {
    margin-bottom: 6px;
}

.__cover_tooltip {
    color: var(--common-text);
    display: flex;
    flex-direction: column;
    font-weight: 300;
    justify-content: center;
    line-height: 1.42857;
    max-width: 100%;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;

    svg {
        height: 16px;
        width: 16px;
    }
}

#description_course.form-control.input-sm {
    border-radius: 22px;
    height: 129px;
    padding: 10px 30px;
}
