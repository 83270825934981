.publish-modal {
    &__title {
        display: flex;
        align-items: center;
        text-transform: initial;
        font-size: 18px;
        font-weight: 600;
    }
    &__end-description {
        max-width: 63%;
        margin: auto;
    }
    &__icon {
        margin-right: 14px;
    }
    &__tags {
        display: flex;
        justify-content: center;
        margin: 25px 0;
        cursor: default;
    }
}
