.auth-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 110px;
    flex-direction: row-reverse;
    position: absolute;
    background-color: rgba(var(--rgb-black), 0.5);

    &__signature {
            a {
                width: 125px;
            }
            @-moz-document url-prefix() {
                margin-right: 15px;
            }

            @media (orientation: portrait) {
                justify-content: center;
            }
            @media (max-width: 350px) {
                flex-direction: column;
                margin-right: 0;
                text-align: center;
            }
    }

    &__card {
        background: var(--white);
        padding: 38px 27px 45px;
        border-radius: 5px;
        max-width: 370px;
        width: 90%;

        &__title {
            font-family: var(--secondary-font);
            font-weight: 700;
            font-size: 18px;
            color: var(--common-text);
            margin-bottom: 22px;
            text-align: center;
        }

        &__reset-password {
            margin-left: auto;
            @media (orientation: portrait) {
                margin-left: unset;
            }

            &__footer {
                margin-top: 25px;
            }
        }

        &__pg-logo {
            width: 80%;
            margin: 0 auto 20px;

            img {
                width: 100%;
            }
        }

        &__input-box {
            margin-bottom: 20px;

            &--username {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}
