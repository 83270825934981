.grades-user-manual-link {
    color: var(--primary);
    display: flex;
    cursor: pointer;
    max-width: 50%;
    align-items: center;

    &__text {
        margin: 0 6px;
        line-height: 22px;
        text-align: initial;
    }
}
