.Editor {
    background-color: rgba(var(--rgb-black), 0.87);
    height: 600px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    .studioNavigation {
        position: absolute;
        z-index: 50;
        top: 38px;
        left: 0;
        padding: 0;
        margin: 0;
        background-color: var(--common-text);
        width: 40px;
        height: calc(100% - 38px);
        li {
            list-style: none;
            text-align: center;
            font-size: 20px;
            color: var(--disabled);
            height: 19px;
            padding: 8px 0;
            transition: all 0.1s ease-in-out;
            cursor: pointer;
            &:hover,
            &[active="true"] {
                color: var(--white);
            }
        }
    }
    .sidebar {
        display: block;
        width: 230px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 50;
        float: left;
        &[show="false"] {
            display: none;
        }
        .header {
            padding: 0 15px;
            border-bottom: 2px solid var(--disabled);
            color: var(--white);
            font-weight: bold;
            position: relative;
            height: 38px;
            border-right: 1px solid var(--common-text);
            img {
                width: 15px;
                margin-right: 10px;
            }
            .title {
                margin-top: 8px;
                display: inline-block;
            }
            .back {
                position: absolute;
                right: 0;
                top: 0;
                width: 35px;
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: var(--disabled);
                transition: all 0.1s ease-in-out;
                &:hover {
                    color: var(--white);
                }
            }
        }
        .sections {
            padding: 0 0 0 40px;
            margin: 0;
            &[show="false"] {
                display: none;
            }
            & > li {
                display: block;
                width: 100%;
                &[show="false"] > ul {
                    display: none;
                }
                .sectionName {
                    padding: 2px 14px 2px 10px;
                    background-color: var(--common-text);
                    width: 100%;
                    display: block;
                    color: var(--white);
                    text-transform: uppercase;
                    font-size: 11px;
                    font-weight: bold;
                    .arrow {
                        float: right;
                        font-size: 13px;
                        cursor: pointer;
                    }
                }
                & > ul {
                    padding: 0;
                    margin: 0;
                    li {
                        &[active="true"] {
                            color: var(--white);
                        }
                        list-style: none;
                        color: var(--disabled);
                        font-size: 14px;
                        padding: 4px 10px;
                        cursor: pointer;
                        transition: all 0.1s ease-in-out;
                        &:hover {
                            color: var(--white);
                            background-color: var(--common-text);
                        }
                        svg {
                            width: 15px;
                            margin-right: 10px;
                        }

                        .isRightIcon {
                            float: right;
                            font-size: 12px;
                            margin-top: 2px;
                            margin-left: 5px;

                            &.isActive {
                                color: var(--success);
                            }

                            svg {
                                margin-right: 0;
                                width: 12px;
                                height: 12px;
                                vertical-align: middle;
                            }

                            .filledIcon {
                                fill: currentColor;
                            }
                        }
                        .content-editable {
                            &[contenteditable="true"]:active,
                            &[contenteditable="true"]:focus {
                                border: none;
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
        .diff {
            padding: 0 0 0 40px;
            margin: 0;
            &[show="false"] {
                display: none;
            }
            select {
                width: 100%;
                background-color: var(--common-text);
                border: 0;
                color: var(--disabled);
                font-size: 14px;
                outline: none;
                padding: 4px;
            }
            li {
                list-style: none;
                color: var(--disabled);
                font-size: 14px;
                padding: 4px 10px;
                cursor: pointer;
                transition: all 0.1s ease-in-out;
                .modifiedFile {
                    float: right;
                    color: var(--warning);
                    position: relative;
                    &:hover > .tooltip {
                        display: block;
                    }
                    .tooltip {
                        position: absolute;
                        background-color: var(--common-text);
                        display: inline-block;
                        width: 135px;
                        text-align: center;
                        left: calc(100% + 8px);
                        color: var(--white);
                        font-size: 14px;
                        top: 50%;
                        transform: translate(0, -50%);
                        -webkit-transform: translate(0, -50%);
                        z-index: 60;
                        display: none;
                    }
                }
                &:hover {
                    color: var(--white);
                    background-color: var(--common-text);
                }
                svg {
                    width: 15px;
                    margin-right: 10px;
                }
            }
        }
    }
    .main {
        float: left;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        .header {
            height: 57px;
            .workingArea {
                padding: 2px 10px;
                background-color: var(--common-text);
                width: 100%;
                display: block;
                color: var(--light-gray);
                text-transform: uppercase;
                font-size: 11px;
                font-weight: bold;
            }
            ul {
                border-bottom: 2px solid var(--disabled);
                min-width: 100%;
                height: 38px;
                padding: 0;
                margin: 0;
                overflow-x: scroll;
                white-space: nowrap;
                overflow-y: hidden;
                z-index: 1001;
                li {
                    &[active="true"] {
                        color: var(--white);
                        background-color: var(--common-text);
                    }
                    display: inline-block;
                    position: relative;
                    height: 100%;
                    padding: 9px 15px;
                    color: var(--disabled);
                    cursor: pointer;
                    border-right: 1px solid var(--common-text);
                    &.showSidebar {
                        padding: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 40px;
                        height: 38px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: var(--common-text);
                        border-bottom: 2px solid var(--disabled);
                        border-top-left-radius: 8px;
                        svg {
                            font-size: 16px;
                            margin-left: 0;
                        }
                    }
                    svg {
                        font-size: 10px;
                        margin-left: 12px;
                    }
                    .isRightIcon {
                        &.isActive {
                            color: var(--success);
                        }
                        svg {
                            margin-left: 0;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
        .monaco {
            overflow: hidden;
            position: relative;

            .filename-popup {
                height: 543px;
                background-color: var(--common-text);
                display: flex;
                justify-content: center;
                align-items: center;

                &__modal {
                    width: 350px;
                    background-color: var(--common-text);
                    border-radius: 5px;
                    border: 0.5px solid var(--light-gray);
                    overflow: hidden;
                    margin-bottom: 100px;

                    &-header {
                        font-family: var(--secondary-font);
                        text-transform: uppercase;
                        text-align: center;
                        color: var(--white);
                        border-bottom: 0.5px solidvar(--light-gray);
                        font-size: 14 px;
                        padding: 7px 0;

                        &-button {
                            border-radius: 50%;
                            font-size: 12px;
                            width: 15px;
                            height: 15px;
                            display: inline-block;
                        }
                    }

                    &-body {
                        padding: 20px 40px;
                        display: flex;
                    }

                    &-input {
                        width: 82%;
                        input {
                            width: 100%;
                            border: none;
                            outline: none;
                            background: var(--common-text);
                            border-radius: 8px;
                            color: var(--white);
                            padding: 7px 12px;
                            font-family: var(--primary-font);
                            font-size: 16px;
                        }
                        &-error {
                            color: var(--danger);
                            font-size: 11px;
                            font-family: var(--primary-font);
                        }
                    }

                    &-extension {
                        width: 18%;
                        text-align: right;
                        color: var(--white);
                        font-family: var(--primary-font);
                        font-size: 16px;
                    }

                    &-footer {
                        button:hover {
                            box-shadow: none;
                        }
                        button {
                            border-width: 1.5px;

                            &.button--danger.button--line {
                                border-color: var(--danger);
                                color: var(--danger);

                                &:hover {
                                    background-color: var(--danger);
                                    color: var(--white);
                                }
                            }

                            &:first-child {
                                margin-right: 20px;
                            }
                        }
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        padding: 0 0 16px;
                    }
                }
            }
        }
    }
}
.Editor .main .header ul::-webkit-scrollbar {
    height: 2px;
    display: none;
}
.Editor .main .header ul:hover::-webkit-scrollbar {
    display: none;
}

.not-open-files {
    width: 100%;
    height: 400px;
    background-color: rgba(var(--rgb-black), 0.2);
    position: relative;

    img {
        width: 30%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }
}
