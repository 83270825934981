.CodingTerminal {
    background-color: var(--common-text);
    font-family: var(--primary-font);
    width: 100%;
    height: 355px;
    border-radius: 5px;

    .whiteLog {
        color: var(--white);
        padding: 4px 6px 0 0;
        display: inline-block;
    }

    .grayLog {
        color: var(--disabled);
        padding: 4px 6px 0 0;
        display: inline-block;
    }

    .greenLog {
        color: var(--success);
        padding: 4px 6px 0 0;
        display: inline-block;
        font-weight: bold;
    }

    .yellowLog {
        color: var(--warning);
        padding: 4px 6px 0 0;
        display: inline-block;
    }

    .throwError {
        background-color: var(--danger-light);
        color: var(--danger);
        border: 1px solid var(--danger);
        font-weight: 600;
        opacity: 0.9;
        display: block;
        margin: 10px 0;
        padding: 8px;
    }

    .logArea {
        padding: 15px 25px;
        font-size: 13px;
        height: 315px;
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(var(--rgb-black), 0.3);
            box-shadow: inset 0 0 6px rgba(var(--rgb-black), 0.3);
            border-radius: 10px;
            background-color: var(--common-text);
        }

        &::-webkit-scrollbar {
            width: 10px;
            background-color: var(--common-text);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            width: 6px;
            -webkit-box-shadow: inset 0 0 6px rgba(var(--rgb-black), 0.3);
            box-shadow: inset 0 0 6px rgba(var(--rgb-black), 0.3);
            background-color: rgba(var(--rgb-black), 0.3);
        }
    }

    .terminalLoader {
        margin-right: 10px;
        vertical-align: text-bottom;
        display: inline;
        svg {
            width: 16px;
            height: 16px;
        }
    }

    .header {
        background-color: rgba(var(--rgb-black), 0.3);
        height: 38px;
        border-radius: 5px;

        ul {
            padding: 0;
            margin-bottom: 0;
            margin-top: 0;

            li {
                list-style: none;
                padding: 10px 25px;
                font-weight: bold;
                color: var(--white);
                font-size: 14px;
                background-color: var(--common-text);
                display: inline-block;
                border-top-left-radius: 5px;
            }
        }
    }
}
