.notion-refresh {

  &__preview-page {
    align-items: center;
    display: flex;
    gap: 4px;
    padding: 8px;
    border: 1px solid #c3c3c3;
    border-radius: 8px;
    margin: 0 auto 20px;
    width: fit-content;
  }
}