@import '../classroomCommons';

.content-map-column {
    grid-column: 1;
    grid-row: 1;

    align-items: center;

    &__onboarding-content {
        left: calc(342px + 10px)!important;
        border-radius: 8px !important;

        @media (min-width: 1381px) {
            left: calc(((100vw - 1335px) / 2 ) + 330px)!important;
        }
    }
}

.content-map-container {

    @media screen and (min-width: $desktop-break-point) {
        position: sticky;
        top: 60px;
        max-height: calc(100vh - 70px);
        overflow: hidden;

        &--onboarding-active {
            z-index: 9999; // onboarding hack.
        }

        // -ms-overflow-style: none; /* IE and Edge */
        // scrollbar-width: none

        &::-webkit-scrollbar {
            width: 8px;
            // display: none;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: rgba(var(--rgb-black), 0.1);
        }

        // &::-webkit-scrollbar-track {}
    }

    &__toggle-container {
        border-radius: $border-radius;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 37px;
        height: 37px;
        position: relative;

        text-align: center;
        background-color: var(--primary);

        &__button {
            font-weight: 600;
            font-size: 15px;
            height: 37px;
            color: var(--white);
            display: flex;
            justify-content: flex-end;
            align-items: center;

            svg{
                width: 20px;
                height: 20px;
                vertical-align: bottom;
            }
        }

        &--open {
            background-color: var(--white);
            width: 100%;
            text-align: right;
            padding: 5px;
            border-bottom-right-radius: unset;
            border-bottom-left-radius: unset;
            border: 1px solid var(--light-gray);
            border-bottom: none;
            color: var(--common-text);
            &__button {
                color: var(--common-text);
                width: 100%;
                text-align: right;
            }
        }
    }

    &__header {
        padding-bottom: 16px;
        border-bottom: 1px solid var(--light-gray);

        &__module-name {
            font-family: var(--primary-font);
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: center;
            color: var(--common-text);

            margin: 20px 0 0;
            padding: 0 30px;

            white-space: -moz-pre-wrap;
            white-space: -o-pre-wrap;
            white-space: -pre-wrap;
            white-space: pre-wrap;

            width: 100%;

            word-wrap: break-word;
        }
    }

    &__skeleton-container {
        height: 60vh;
        background-color: var(--white);
        padding: 20px 10px;
        border-radius: $border-radius;
        border: 1px solid var(--light-gray);

        &__toggle-button {
            margin-bottom: 0;
            padding: 2px;
            border-radius: $border-radius;
        }

        @media screen and (min-width: $desktop-break-point) {
            padding: 2px;
            width: $content-map-width;
            border-radius: $border-radius;
            margin-bottom: 10px;
        }
    }

    &__module-container {
        border: 1px solid var(--light-gray);
        background-color: var(--white);
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        &--mobile {
            border-top-right-radius: $border-radius;
            border-top-left-radius: $border-radius;
        }

        @media screen and (min-width: $desktop-break-point) {
            min-width: $content-map-width;
        }
    }
}

.hide-panel {
    display: none;
}

.toggle-content-map {
    text-align: right;
}
