.Browser {
    &.light {
        border: 3px solid var(--light-gray);

        > .header {
            background-color: var(--light-gray);

            .tabs li {
                background-color: var(--background);
                color: var(--common-text);
                &:last-child {
                    background-color: var(--light-gray);
                    color: var(--disabled);
                }
            }
        }

        .options {
            background-color: var(--background);

            .navigation li:hover {
                background-color: var(--light-gray);
            }

            .url {
                background-color: var(--light-gray);
                color: var(--common-text);

                width: auto;
                max-width: 90%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                .protocolo {
                    color: var(--common-text);

                    svg {
                        width: 16px;
                        height: 16px;
                        margin-right: -2px;
                        vertical-align: text-bottom;
                    }
                }
            }

            .theme {
                color: var(--common-text);
            }
        }

        .emptyArea {
            background-color: var(--white);
        }
    }

    &.dark {
        border: 3px solid var(--common-text);

        > .header {
            background-color: rgba(var(--rgb-black), 0.9);

            .tabs li {
                background-color: var(--common-text);
                color: var(--light-gray);

                &:last-child {
                    background-color: transparent;
                    color: var(--light-gray);
                }
            }
        }

        .options {
            background-color: var(--common-text);
            border-bottom: var(--disabled) solid 1px;

            .navigation li:hover {
                background-color: rgba(var(--rgb-black), 0.3);
            }

            .url {
                background-color: rgba(var(--rgb-black), 0.3);
                color: var(--light-gray);
                width: auto;
                max-width: 90%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                .protocolo {
                    color: var(--light-gray);

                    svg {
                        width: 16px;
                        height: 16px;
                        margin-right: -2px;
                        vertical-align: text-bottom;
                    }
                }
            }

            .theme,
            .terminal {
                color: var(--light-gray);
                background-color: rgba(var(--rgb-black), 0.3);
            }
        }

        .emptyArea {
            background-color: var(--common-text);

            img {
                position: relative;
                top: -16px;
            }
        }

        .BrowserConsole {
            background-color: var(--common-text);
            border-left: 1px solid var(--disabled);
            .header {
                background-color: rgba(var(--rgb-black), 0.3);
            }

            .logArea {
                background-color: var(--common-text);

                .grayLog {
                    color: var(--white);
                }
            }
        }
    }
}

/*BrowserConsole*/

.BrowserConsole {
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid var(--light-gray);

    .header {
        height: 30px;
        background-color: var(--background);
        .closeBtnConsole {
            width: 40px;
            height: 100%;
            display: inline-block;
            border-right: 1px solid var(--light-gray);
            color: var(--disabled);
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            text-align: center;
            padding-top: 5px;

            svg {
                width: 16px;
                height: 16px;
                vertical-align: text-bottom;
            }

            &:hover {
                color: var(--common-text);
            }
        }

        .consoleTitle {
            display: inline-block;
            text-align: center;
            font-size: 16px;
            color: var(--disabled);
            padding: 0 15px;
        }
    }

    .logArea {
        height: calc(100% - 35px);
        background-color: var(--white);
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(var(--rgb-black), 0.3);
            box-shadow: inset 0 0 6px rgba(var(--rgb-black), 0.3);
            border-radius: 6px;
            background-color: var(--light-gray);
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: var(--white);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            width: 6px;
            -webkit-box-shadow: inset 0 0 6px rgba(var(--rgb-black), 0.3);
            box-shadow: inset 0 0 6px rgba(var(--rgb-black), 0.3);
            background-color: rgba(var(--rgb-black), 0.9);
        }

        .grayLog,
        .throwError {
            display: flex;

            svg {
                min-width: 24px;
                max-width: 24px;
            }
        }

        .grayLog {
            color: var(--common-text);
            padding: 3px 10px;
            font-size: 13px;

            svg {
                font-size: 11px;
                color: var(--disabled);
                margin-right: 5px;
                color: var(--info-light);
            }
        }

        .throwError {
            background-color: var(--danger-light);
            color: var(--danger);
            border-top: 1px solid var(--danger);
            border-bottom: 1px solid var(--danger);
            margin: 10px 0;
            padding: 5px;
            font-size: 13px;
            font-weight: 600;

            svg {
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
}

.Browser {
    border-radius: 8px;

    .header .tabs {
        padding: 0;
        margin-bottom: 0;
        margin-top: 0;

        .favicon {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            margin-top: 2px;
        }

        li {
            padding: 6px 15px;
            font-size: 14px;
            list-style: none;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            display: inline-flex;

            svg {
                width: 16px;
                height: 16px;
            }

            &:last-child {
                padding: 6px 10px;
                border: 0;
            }
        }
    }

    .options {
        padding: 5px 10px;

        .navigation {
            padding: 0;
            margin: 0;
            float: left;
            width: 90px;

            li {
                float: left;
                color: var(--disabled);
                font-size: 17px;
                transition: all 0.1s ease-in-out;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                &:last-child {
                    font-size: 15px;
                    font-weight: bold;
                    margin-top: 1px;
                }
            }
        }

        .url {
            float: left;
            margin-left: 15px;
            width: calc(100% - 240px);
            padding: 7px 20px;
            border-radius: 20px;
            font-size: 14px;
        }

        .terminal {
            width: 30px;
            color: var(--common-text);
            font-size: 14px;
            float: right;
            text-align: center;
            padding-top: 4px;
            height: 30px;
            cursor: pointer;
            margin: 1px 16px 0 5px;
            background-color: var(--light-gray);
            border-radius: 50%;

            svg {
                width: 20px;
                height: 20px;
                vertical-align: sub;
            }
        }

        .url .protocolo {
            font-size: 12px;
            margin-right: 15px;
        }

        .theme {
            width: 30px;
            color: var(--common-text);
            font-size: 16px;
            padding-top: 4px;
            margin-top: 1px;
            height: 30px;
            cursor: pointer;
            background-color: var(--light-gray);
            border-radius: 50%;
            float: right;
            text-align: center;
            padding-top: 4px;

            svg {
                width: 20px;
                height: 20px;
                vertical-align: sub;
            }
        }
    }

    .browserLoader {
        margin-right: 10px;
        margin-top: 2px;
        svg {
            vertical-align: baseline;
        }
    }

    .emptyArea {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
        }
    }

    .renderArea {
        position: relative;

        .frame {
            border: 0;
        }
    }
}

.both {
    clear: both;
}
