.__report_quantity_card {
    display: block;
    padding: 5px 20px;
    max-width: -moz-fit-content;
    max-width: fit-content;

    &._quantity {
        background-color: var(--primary-light);
        border-radius: 8px;
    }

    ._quantity_text {
        color: var(--white);
        font-weight: 700;
    }
}

.__report_info_card {
    display: block;
    padding: 15px 0;

    .__report_data {
        .__report_dates_cards {
            display: inline-block;
            margin: 15px 15px 15px 0;
            padding: 5px 20px;
            min-width: 180px;
            min-height: 50px;

            &._release_date {
                background-color: var(--success-light);
                border-radius: 8px;
            }

            &._due_date {
                border-radius: 8px;
                min-width: 150px;
                background-color: var(--warning-light);
            }

            ._date_text {
                font-weight: 700;
            }
        }
    }
    .__report_commission {
        font-size: 18px;
        font-weight: 700;
        color: var(--common-text);
    }

    .__report_total_responses {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-right: 30px;

        .ko-progress-circle {
            margin: 0;
        }

        .__report_responses {
            text-align: center;
            margin-top: 5px;
        }
        .__report_responses_text {
            margin-top: 0;
            font-family: var(--primary-font);
        }
    }

    .__report_total_approved {
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .ko-progress-circle {
            margin: 0;
        }
        .__report_approved {
            text-align: center;
            margin-top: 5px;
        }

        .__report_approved_text {
            margin-top: 0;
            font-family: var(--primary-font);
        }
    }
}
