@mixin draft {
    font-style: italic;
    color: var(--common-text);
}

.exercise-item__box {
    box-sizing: border-box;
    margin-bottom: 25px;
    &:last-child {
        margin-bottom: 0;
    }

    height: 72px;
    border: 0.5px solid var(--light-gray);
    background-color: var(--white);
    border-radius: 12px;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 26px;
        padding-right: 18px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &__message {
        width: 100%;
        opacity: 1;
        border-radius: 0 0 12px 12px;
        overflow: hidden;

        &--closed {
            transition: 0.6s ease;
            -webkit-transition: 0.6s ease;
            -o-transition: 0.6s ease;
            max-height: 0;
            opacity: 0;
        }
    }

    &--with-message {
        height: 107px;
        border-color: var(--success-light);

        [class*='box__message'] > :first-child {
            padding: 6px 21px;
        }

        &--closed {
            transition: 0.6s ease;
            -webkit-transition: 0.6s ease;
            -o-transition: 0.6s ease;
            height: 72px;
            border-color: var(--light-gray);
        }
    }

    &__group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        &__data {
            margin-right: 23px;
            &__item {
                display: flex;
                flex-direction: column;
                padding-right: 30px;
                width: 308px;
                height: 100%;
                justify-content: space-between;
                &.sep {
                    border-right: 1px solid var(--common-text);
                }
                .item-title {
                    margin: 0;
                    padding: 0;

                    &--draft {
                        @include draft;
                    }
                }
                &--center-v {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    height: 42px;
                    min-width: max-content;
                    max-width: 250px;
                }
                &--tags {
                    font-family: var(--primary-font);
                    font-size: 13px;
                    color: var(--common-text);
                    .item__tag {
                        border-right: 0.5px solid var(--common-text);
                        padding-right: 12px;
                        padding-left: 12px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 80px;
                        display:inline-block;

                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                            border-right: unset;
                        }
                    }
                }
                .issued__box {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 42px;
                    &__group {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding-right: 15px;
                        font-family: var(--primary-font);
                        font-size: 14px;
                        &:last-child {
                            padding-right: 0;
                        }
                        &__title {
                            color: var(--common-text);
                        }
                        &__detail {
                            color: var(--common-text);

                            &--draft {
                                @include draft;
                            }
                        }
                    }
                }
            }
        }

        &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            &--first {
                margin-right: 10px;
            }
        }
    }

    &--draft {
        @include draft;
    }
}
