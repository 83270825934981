.final-grade-card {
    &__content {
        width: 300px;
        height: 60px;
        display: flex;
        position: relative;
        background-color: var(--white);
        border-radius: 8px;
        margin-bottom: 10px;
        margin-right: 14px;
    }
    &__text-container {
        width: 38%;
        display: flex;
        align-items: center;
        padding-left: 12px;
    }
    &__text {
        font-size: 15px;
        color: var(--disabled);
        font-weight: 600;
    }
    &__description-container {
        width: 62%;
        background-color: #303030;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        &--success {
            background-color: var(--success);
        }
        &--warning {
            background-color: var(--warning);
        }
    }
    &__description {
        color: var(--white);
        font-weight: 900;
        font-size: 16px;
        font-family: Poppins;
    }
    &__grade-container {
        position: absolute;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        top: 15%;
        left: 31%;
        border: 2px solid #303030;
        background-color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        &--success {
            border: 2px solid var(--success);
        }
        &--warning {
            border: 2px solid var(--warning);
        }
    }
    &__grade {
        color: #303030;
        font-weight: bold;
        font-size: 26px;
        font-family: Poppins;
        margin-left: 2px;
        margin-top: 2px;
        &--success {
            color: var(--success);
        }
        &--warning {
            color: var(--warning);

        }
    }
}
