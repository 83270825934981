.certificate-page {
    background: var(--primary-dark);
    height: 100%;
    margin: auto;
    width: 95%;
    display: flex;
    margin-top: 20px;
    border-radius: 5px;
    min-height: 700px;

    &__message {
        text-align: center;
        color: var(--white);
        font-size: 22px;
        font-weight: 600;
        flex-grow: 1;
        min-width: 400px;
        position: relative;

        &--icon {
            margin-top: 80px;
            height: 42px;
            width: 42px;
            color: var(--success);
        }

        &--title {
            margin: 30px 0;
        }

        &--alias {
            color: var(--success);
            margin-top: -10px;
            margin-bottom: 30px;
        }

        &--logo {
            width: 140px;
            display: block;
            margin: auto;
            margin-top: 140px;
            filter: invert(100%);

            @media (max-width: 768px) {
                margin-top: 45px;
                margin-bottom: 35px;
            }
        }

        &--buttons {
            display: flex;
            position: absolute;
            width: 100%;
            right: 0;
            bottom: 50px;
            height: 40px;
            align-items: center;
            justify-content: center;

            @media (max-width: 768px) {
                position: initial;
                padding: 0 20px 0 20px;
            }
        }

        &--back-button,
        &--url {
            line-height: 20px;
        }

        &--url {
            color: var(--success);
            font-size: 21px;
            font-weight: 500;

            svg{
                vertical-align: bottom;
            }
        }

        &--back-button {
            color: var(--white);
            font-size: 21px;
            font-weight: 500;
            svg{
                vertical-align: bottom;
            }
        }

        &--filler{
            flex: 1 1 auto;
            max-width: 120px;
        }

        &--download-certificate {
            margin: auto;
        }
    }

    &__images {
        &--main {
            mix-blend-mode: lighten;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &--secondary {
            display: flex;
            align-items: flex-end;
            margin: 0 30px 50px 0;
            justify-content: flex-end;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 80px;
    }
}
