.delete-block-warning-modal-body {
    .warning-title {
        color: var(--danger);
        text-align: left;
        font-size: 18px;
        font-weight: bold;
    }
    .warnings-list {
        padding: 0;
        text-align: left;
        font-size: 16px;
        list-style: none;
        margin-top: 20px;

        li {
            display: flex;
            vertical-align: center;
            margin-bottom: 15px;
            svg {
                color: var(--danger);
                font-size: 26px;
                margin-right: 15px;
            }
        }
    }
}