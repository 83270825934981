.__login {
    &_page {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 50px;

        .link {
            color: var(--info);
        }
    }

    &_container {
        background: var(--white);
        padding-top: 45px !important;
        @media (orientation: portrait) {
            width: 90%;
            margin-top: -15px;
            .secondary_title {
                font-size: 17px;
            }
        }

        @media (orientation: landscape) {
            width: 400px;
        }
        .form-group .control-label {
            @media (max-width: 360px) {
                margin-bottom: 0px;
                padding-left: 10px;
                font-weight: 250;
            }
        }
        .secondary_title {
            @media (max-width: 360px) {
                margin: 5px auto 30px;
            }
        }
    }
}
