.quiz-report-container {
    margin: auto;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .quiz-report-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .quiz-report-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .quiz-report-container {
        padding: 0 12px;
        width: 1200px;
    }
}

@media (min-width: 1500px) {
    .quiz-report-container {
        width: 1500px;
    }
}
