.digital-tools {
    .title {
        font-size: 20px;
        line-height: 28px;
        color:var(--common-text);
        font-weight: 600;
        font-family: var(--primary-font);
        margin-bottom: 24px;
        margin-top: 0;
        @media (max-width: 768px){
            font-size: 18px;
            line-height: 25px;
        }
    }

    .toolList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        @media (max-width: 768px) {
            flex-direction: column;

        }
    }

    .tool {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px;
        min-height: 168px;
        width: 49.5%;
        margin-bottom: 16px;
        background-color:var(--white);
        @media (max-width: 768px) {
            width: 100%;

        }

        &:last-child {
            margin-bottom: 72px;
        }

        .nameAndDescription {
            word-break: break-word;
            white-space: pre-wrap;
            margin-bottom: 8px;
        }

        .name {
            margin: 0;
            margin-bottom: 8px;
            font-size: 18px;
            line-height: 25px;
            color: var(--common-text);
            font-weight: bold;
            font-family: var(--primary-font);
            @media (max-width: 768px){
                font-size: 16px;
                line-height: 22px;
            }
        }
        .description {
            font-size: 15px;
            line-height: 21px;
            color: var(--common-text);
            font-family: var(--primary-font);
            margin-bottom: 0;
            @media (max-width: 768px){
                font-size: 14px;
                line-height: 20px;
            }
        }
        .toolLinks{
            display: flex;
            justify-content: flex-start;
            & > *:not(:last-child) {
                margin-right: 12px;
            }
        }
    }
}
