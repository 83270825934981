@import '../../classroomCommons';

.lesson-list-container {

    width: 100%;

    @media screen and (min-width: $desktop-break-point) {
        overflow-y: auto;
        max-height: calc(100vh - 240px);
    }

    &::-webkit-scrollbar {
        width: 8px;
        // display: none;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgba(var(--rgb-black), 0.1);
    }

    &__lessons {
        padding: 0;
        margin: 0;
    }
}