.user-fiscal-id {
  &__container {
    position: relative;

    & .form-group {
      z-index: inherit;
    }
  }

  &__title {
    flex-direction: row;
  }

  &__fields {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    position: relative;
  }
}
