.block-container {
    background-color: var(--white);
    padding: 30px 20px;
    border-radius: 7px;
    border: 1px solid var(--llight-gray);

    &:not(:first-child) {
        margin: 10px 0 0;
    }

    &:last-child{
        margin-bottom: 10px;
    }
}
.quiz-block-container {
    &__thick-separator{
        height: 21px;
        width: 100%;
        background-color: var(--background);
        margin-top: 5px;
    }
    
    &__thin-separator{
        height: 13px;
        background-color: var(--background);
        margin: 16px 0 32px 0;
    }
}