.header-feedback{
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__title{
        margin-top: 24px;
        font-family: var(--primary-font);
        font-size: 15px;
        color: var(--common-text);
    }

}