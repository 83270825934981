.choice-container {
    width: 100%;
    margin: 8px auto;
    padding: 15px 16px;
    border-radius: 23px;
    border: solid 1px var(--light-gray);
    cursor: pointer;
    display: flex;
    align-items: center;

    &__input--selected {
        background-color: var(--warning-light);
    }

    &__input--right-feedback {
        background-color: var(--background);
        border: solid 1px var(--success);
    }

    &__input--wrong-feedback {
        background-color: var(--background);
        border: solid 1px var(--warning);
    }

    &__input-container {
        display: flex;
        width: 100%;
    }

    & &__input {
        align-self: center;
    }

    & &__input--image {
        margin-bottom: 10px;
        position: relative;
        top: 3px;
    }

    & &__label {
        cursor: pointer;
        padding: 0 8px;
        width: 85%;
        overflow-wrap: break-word;
    }
    
    &--image {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        
        padding: 8px;
        width: 208px;
    }
    

    &--image-preview {
        border: none;

        .radio-button {
            display: none;
        }
    }

    &__image-container {
        text-align: right;
        &--preview {
            position: fixed;
            
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            z-index: 2000;
            
            background-color: rgba(var(--rgb-black), 0.6);
            
            text-align: center;

            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
        }

        &__image {
            border-radius: 8px;
            width: 190px;
            height: 238px;
            object-fit: contain;
            margin-bottom: 10px;
            &--preview {
                width: unset;
                height: unset;
                max-width: 85vw;
                max-height: 85vh;
                object-fit: cover;
                align-self: center;
                margin: 0 auto;
            }
        }

        &__preview-button {
            border: .5px solid var(--light-gray);
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin-top: -14px;
            background-color: var(--white);

            &--preview {
                vertical-align: top;
                transform: rotate(0deg)!important;
                border: none;
                background: none;
                margin-top: -20px;
                color: var(--white);
                align-self: center;
            }
        }
    }

    .checkbox {
        padding: 0px;
        margin: 0 0 14px 20px;
    }
    .checkbox__text-main{
        padding-left: 16px;
        padding-top: 3px;
        font-family: var(--secondary-font);
        font-size: 15px;
        line-height: 1.27;
        color: var(--common-text);
        font-weight: normal;
    }

    @media screen and (max-width: 730px) {
        border-radius: 12px;
    }
}