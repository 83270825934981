.administration-landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: fixed;
    height: 80%;
    width: 100%;
    @media (orientation: portrait) {
        justify-content: space-evenly;
        padding: 20% 0;
        height: 90%;
    }
}

.administration-landing__title {
    height: 33px;
    font-family: var(--secondary-font);
    font-size: 24px;
    font-weight: 600;
    line-height: 1.46;
    color: var(--common-text);
    margin: 0;
}

.administration-landing__subtitle {
    font-family: var(--primary-font);
    height: 23px;
    font-size: 18px;
    line-height: 1.28;
    color: var(--common-text);
    margin: 2vh 0 4vh;
}
