.__list_exercises {
    margin-bottom: 0;
    padding: 0;
    li {
        border-radius: 15px;
        border: 1px solid var(--primary);
        color: var(--primary);
        display: inline-block;
        margin-right: 15px;
        padding: 10px 20px;
    }
}

.__exercise_title {
    color: var(--primary);
    font-size: 14px;
    letter-spacing: 1px;
    margin-bottom: 15px;
    text-transform: uppercase;
}
