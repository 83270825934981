.issues-options-modal {
    background: var(--white);
    border-radius: 5px;
    width: 600px;
    color: var(--common-text);

    @media (max-width: 768px) {
        max-width: 85vw;
    }

    &__title {
        display: flex;
        height: 60px;
        border-bottom: 1px solid var(--light-gray);
        width: 100%;
        font-size: 15px;
        line-height: 60px;
        padding: 0 25px 0 30px;

        p {
            font-family: Poppins;
            font-weight: 400;
        }

        &--close {
            background: transparent;
            border: none;
            margin-left: auto;
            margin-right: 0;
            margin-top: -3px;
        }
    }

    &__options {
        padding: 20px 30px;
        font-weight: 600;
        border-bottom: 1px solid var(--light-gray);

        &--subtitle {
            margin-bottom: 30px;
        }

        &--textarea {
            margin-bottom: 20px;
            margin-top: -15px;

            textarea {
                font-size: 15px;
                font-weight: 400;
            }
        }

        &--item {
            margin-bottom: 10px;
            min-height: 25px;
            input {
                display: inline-flex;
                position: fixed;
                margin-right: 15px;
            }

            p {
                margin-left: 35px;
            }
        }

        &__uploader {
            padding-top: 20px;
        }
    }

    &__success {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px solid var(--light-gray);
        &--icon {
            height: 100px;
            padding-top: 40px;
            color: var(--primary);
        }

        &--team {
            margin: 25px 0 35px 0;
            color: var(--primary);
        }

        @media (max-width: 768px) {
            padding: 20px;
        }
    }

    &__buttons {
        border-top: 1px solid var(--light-gray);
        padding: 20px 40px;
        display: flex;
        justify-content: center;

        &--back {
            margin-right: 15px;
        }

        @media (max-width: 768px) {
            margin: 0 auto;
            padding: 20px;

            button {
                width: 45%;
            }
        }
    }
}
