@mixin linkStyles {
    color: var(--primary);
    &:hover {
        text-decoration: none;
        color: var(--primary-dark);
    }
}
.recover-msg {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 110px;
    flex-direction: row-reverse;
    position: absolute;
    background-color: rgba(var(--rgb-black), 0.5);
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        padding: 18px;
        justify-content: center;
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: var(--white);
        padding: 38px 27px 45px;
        border-radius: 5px;
        max-width: 370px;
        width: 90%;
        height: 439px;

        &__pg-logo {
            width: 80%;
            margin: 0 auto 20px;

            img {
                width: 100%;
            }
        }

        &__input-box {
            margin-bottom: 20px;

            &--username {
                input.input-text {
                    height: 35px;
                }

                p {
                    font-size: 14px;
                }
            }
        }

        &__links {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            @media (max-width: 378px) {
                flex-direction: column;
                align-items: center;
            }

            a {
                @include linkStyles;
                font-size: 12px;
                @media (max-width: 378px) {
                    font-size: 13px;
                    &:nth-child(even) {
                        margin-top: 5px;
                    }
                }
            }

            a:nth-child(even)::before {
                content: '-';
                margin: 0 5px;

                @media (max-width: 378px) {
                    content: unset;
                }
            }
        }
        &__icon {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 24px;
        }

        &__title {
            font-family: var(--secondary-font);
            font-weight: 700;
            color: var(--common-text);
            font-size: 18px;
            line-height: 25px;
            text-align: center;
        }

        &__text {
            margin-top: 18px;
            font-family: var(--primary-font);
            font-weight: 400;
            font-size: 15px;
            line-height: 18.86px;
            text-align: center;
            color: var(--common-text);
        }

        &__footer {
            margin-top: 18px;
            font-family: var(--primary-font);
            font-weight: 400;
            font-size: 15px;
            line-height: 18.86px;
            text-align: center;
            color: var(--common-text);
        }
        &__button {
            font-size: 15px;
            margin-top: 15px;
            margin-bottom: 15px;
            &--link {
                @include linkStyles;
            }
        }
    }
}
