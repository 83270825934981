.TeachersList {
    display: flex;
    flex-direction: column;
    padding: 0%;

    .title {
        color:var(--common-text);
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        text-align: left;
        font-family: var(--primary-font);
        margin-bottom: 24px;
        @media (max-width: 768px) {
            color:var(--common-text);
            font-size: 18px;
            line-height: 25px;
            font-weight: 600;
            text-align: left;
            font-family: var(--primary-font);
        }
    }

    .desktopGrid {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        justify-content: space-between;

        .teacherCard {
            width: 49.5%;
            height: 123px;
            display: flex;
            flex-direction: row;
            background-color:var(--white);
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 72px;
            }
            .avatar {
                align-self: center;
                .profileImg {
                    padding: 8px;
                    height: 120px;
                    width: 120px;
                    margin-right: 16px;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .name {
                color: var(--common-text);
                font-size: 18px;
                line-height: 25px;
                font-family: var(--primary-font);
                font-weight: 600;
            }

            .role {
                color: var(--common-text);
                font-size: 15px;
                margin-top: -10px;
                font-family: var(--primary-font);
                margin-bottom: 10px;
            }

            .email {
                color: var(--common-text);
                font-size: 14px;
                line-height: 21px;
                font-family: var(--primary-font);
            }

            .teacherDetails {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }

    .mobileGrid {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .teacherCard {
            width: 100%;
            height: 91px;
            display: flex;
            flex-direction: row;
            background-color:var(--white);
            margin-bottom: 10px;

            .avatar {
                align-self: center;
                .profileImg {
                    padding: 8px;
                    height: 90px;
                    width: 90px;
                    margin-right: 8px;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .name {
                color: var(--common-text);
                font-size: 16px;
                line-height: 22px;
                font-family: var(--primary-font);
                font-weight: 600;
            }

            .email {
                color: var(--common-text);
                font-size: 14px;
                line-height: 20px;
                font-family: var(--primary-font);
            }

            .teacherDetails {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }
}
