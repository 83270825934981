.request-redo-modal {
    font-family: var(--primary-font);
    width: 100%;
    text-align: initial;

    &__title {
        font-family: var(--secondary-font);
        color: var(--common-text);
        letter-spacing: 0.02em;
        font-weight: 300;
        font-size: 15px;
        text-align: center;
    }

    &__reasons,
    &__explanation {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin: 1.4rem 0 0.7rem;
        padding-top: 1rem;

        &-title {
            margin: 0;
            color: var(--common-text);
            font-weight: 600;
            font-size: 15px;
        }
    }

    &__limit-counter {
        font-size: 14px;
        margin-left: auto;
        margin-right: 1.2rem;
    }
}
