.block-nav-container {

    background-color:var(--white);
    color: var(--common-text);
    padding: 10px;

    &--active-quiz {
        color: var(--info);
        box-shadow: var(--darker-box-shadow);
        margin-bottom: 10px;
    }

    &__step-counter {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__step {
            flex: 1;
            border: 1px solid var(--light-gray);
            margin: 5px;

            &--active {
                border: 1px solid var(--common-text);
            }
        }
    }

    &__icon{
        width: 20px;
        height: 20px;
    }

    &__nav {
        align-items: center;
        display: flex;
        font-size: 15px;
        font-weight: 600;
        height: 37px;
        justify-content: space-between;
        line-height: 24px;

    }
}
