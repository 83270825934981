.exercise {
    position: relative;
    top: 0px;
    @media (orientation: portrait) {
        top: 60px;
    }

    &-search__box__inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        &__group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &--text {
                width: 100%;
                margin-right: 16px;
                &:last-child {
                    margin-right: 0;
                }
            }

            &--submit {
                width: 75%;
                .button-search {
                    margin-top: 25px;
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }
    }

    &-results {
        width: 100%;
        background-color: var(--background);
    }

}

