div.user-form {
    &__user-state{
        position: relative;
        z-index: 8;
    }
    &__birthdate{
        position: relative;
        z-index: 7;
    }
    &__country{
        position: relative;
        z-index: 6;
    }
    &__state{
        position: relative;
        z-index: 5;
    }
    &__city{
        position: relative;
        z-index: 4;
    }
    &__role{
        position: relative;
        z-index: 3;
    }
    &__user-type{
        position: relative;
        z-index: 2;
    }
    &__id-container{
        display: flex;
        align-items: flex-start;
        z-index: 1;
        position: relative;
        gap: 20px;
    }
    &__id-field{
       width: 50%;
    }
    &__id-type-field{
        width: 50%;
    }
    &--save {
        margin-left: 20px;
    }
}
