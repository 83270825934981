.permissions-table_col {
    margin-left: 7%;
}

.permissions-table_row {
    padding: 10px 0;
}

.permissions-card_title {
    color: var(--common-text);
    font-size: 18px;
    font-family: var(--secondary-font);
    font-weight: 600;
    margin: 15px 0;
    padding-left: 15px;
}

.permission-table_separator {
    width: 85%;
    border: 0.5px solid var(--light-gray);
}
