.exercise_container {
    background-color: var(--white);
    border-radius: 7px;
    border: 1px solid var(--light-gray);
    padding: 14px 40px 45px;
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;
}

.span__usedTooltip {
    margin-right: 15px;
    color: var(--warning);
}

.excercise-title {
    display: inline-block;
}
