html {
    font-size: 16px !important;
}

.payment__container {
    padding-top: 30px;
    
    .pcard {
        background-color: var(--white);
        &__dropdown {
            background-color: var(--white);
        }
        &__dropdown-button {
            padding-top: 12px;
        }
        &__footer {
            margin-bottom: 14px;
        }
    }
    &__sidebar {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
    }
    .sidebar__container {
        padding-right: 30px;
    }
    .sidebar__success {
        background-color: var(--white);
    }

    &--mobile {
        padding-top: 120px;
        .wrap-container {
            display: flex;
            flex-direction: row;
            justify-content: center;    
        }
        .sidebar__blur {
            display: none;
        }
        .sidebar__container {
            padding-top: 82px;
            width: 100%;
            padding-left: 12px;
            padding-right: 12px;
        }
    }
}

.container-mobile {
    .CustomCargando {
        margin-top: 100px;
    }
}