.nav.nav-tabs {
    border: 0;
    margin-bottom: 25px;
    font-family: var(--secondary-font);
    font-weight: 500;

    & > li,
    & > li > a {
        border-bottom: 3px solid transparent;
        color: var(--common-text) !important;
    }

    & > li > a,
    & > li.active > a {
        background-color: var(--white) !important;
        border: unset;
        padding: 15px 20px;
    }

    & > li > a:hover,
    & > li > a:focus,
    & > li > a:active {
        color: var(--primary) !important;
    }

    & > li.active > a {
        border-bottom: 3px solid var(--primary);
        color: var(--primary) !important;

        &:hover,
        &:focus,
        &:active {
            border: unset;
            border-bottom: 3px solid var(--primary);
        }
    }
}
