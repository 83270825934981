.back_button{
    border: none;
    background: transparent;
    font-family: var(--primary-font);
    font-size: 15px;
    font-weight: normal;
    color: var(--common-text);
    padding-left: 0px;
    margin-bottom: 30px;
}

.back_button > .leftIconContainer {
    color: var(--primary);
    margin-right: 11px;

    &:active,
    &:hover {
        color: var(--primary-dark);
    }
}
