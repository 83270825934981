.date-pill-container{
    margin-top: 10px;
    margin-bottom: 0;

    display: inline-flex;

    font-family: var(--primary-font);

    border: 1px solid var(--light-gray);
    border-radius: 16px;
    padding: 4px 16px;

    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--common-text);

    &__icon {
        color: var(--primary);
        margin-right: 5px;
    }
}
