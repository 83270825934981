@import "../../../node_modules/react-notion-x/src/styles.css";

.text-block-iframe {
    border: 0;
    width: 100%;
    overflow: hidden;
}

figure.notion-asset-wrapper {
    max-width: 100%;
}