.card-block-icon {
    margin: 0 5px 0 5px;
    filter: invert(43%) sepia(0%) saturate(0%) hue-rotate(17deg) brightness(101%) contrast(92%);
    height: 16px;
    vertical-align: text-bottom;
}

.block-icon {
    margin: 0 10px 0 0;
    width: 27.6px;
    height: 19.4px;
    vertical-align: bottom;
    filter: invert(43%) sepia(0%) saturate(0%) hue-rotate(17deg) brightness(70%) contrast(92%);
}

.block-icon-default{
    color: var(--disabled);
}