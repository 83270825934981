@import '../../classroomCommons';

.topic-nav-container{
    align-items: center;
    background-color:var(--white);
    box-shadow: var(--darker-box-shadow);
    color: var(--common-text);
    display: flex;
    font-size: 15px;
    height: 60px;
    justify-content: space-between;
    line-height: 24px;
    margin-bottom: 10px;
    padding: 14px 0;

    .common-text {
        color: var(--common-text);
    }

    .button__text {
        position: relative;
        top: 3px;
    }

    &--skeleton {
        border-radius: 8px;
        justify-content: space-around;
        box-shadow: var(--darker-box-shadow);
        height: 60px;
        margin-bottom: 0;

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        padding: 0 8px;

        @media(min-width: $desktop-break-point) {
            box-shadow: none;
            border: 1px solid var(--light-gray);
            border-radius: 8px;
            height: $topic-nav-desktop-height;
        }
    }

    &--active {
        color: rgb(var(--primary));
    }

    &--pulse {
        position: relative;
        overflow: hidden;
        padding-left: 30px;
        
        &::before {
            content: "";
            width: 1px;
            height: 1px;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            border: 2px solid rgba(67, 73, 181, .4);
            animation: pulse-animation 1s infinite;
        }
    }

    &--disabled{
        opacity: 0.3;
    }

    &--hidden{
        display: none;
    }

    &__icon {
        color: var(--primary);
        height: 20px;
        padding-top: 7px;
        margin: 0 5px;
    }

    &__filler{
        width: 46px;
    }

    &__title {
        font-family: var(--primary-font);
        font-size: 14px;
        margin: 0;
    }


    @media(min-width: $desktop-break-point) {
        border-radius: 7px;
        padding: 5px;
        height: $topic-nav-desktop-height;
        border: 1px solid var(--light-gray);
        box-shadow: none;
    }

    @keyframes pulse-animation {
        from {
            opacity: 1;
            transform: scale3d(0.75, 0.75, 1);
        }
    
        to {
            opacity: 0;
            transform: scale3d(30, 30, 1);
        }
    }
}
