.static-feedback {
    margin-top: 1.5rem;

    &__student-hand-in {
        background-color: var(--background);
        padding: 20px;
        border-radius: 6px;
    }

    &__teacher-redo-feedback {
        border: solid thin var(--light-gray);
        padding: 15px;
        border-radius: 6px;
        margin-top: 1.5rem;
    }

    &__teacher-feedback {
        border: solid thin var(--light-gray);
        padding: 20px;
        border-radius: 6px;
        margin-top: 1.5rem;
    }

}