@import './variables.scss';

.roadmapSlider {
    background-color: $back;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
    width: 100%;

    &.paddingLeft .slick-list {
        padding-left: 41px;
        @media (orientation: portrait) {
            padding-left: 52px;
        }

    }

    .slick-track {
        margin: 0 auto;
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .slick-disabled {
        display: none;
    }
    .arrowContainer {
        $topRegulation: 36%;
        height: $defaultHeight + 15px;
        background-color: $back;
        .icon.arrow.right {
            width: 10px !important;
            height: 20px !important;
            top: $topRegulation;
        }
        .icon.arrow.left {
            width: 10px !important;
            height: 20px !important;
            top: $topRegulation;
        }
    }
    .roadBox {
        display: flex !important;
        flex-direction: row;
        &:focus{
            outline: none;
        }
    }
    .roadItem {
        width: $itemWidth;
        height: $defaultHeight;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $back;

        .ko-progress-circle {
            margin: 0;
            margin-bottom: 8px;
            .ko-progress-circle__overlay {
                margin-left: 4px;
                margin-top: 4px;
            }
        }
        p {
            margin-bottom: 0;
            color: var(--common-text);
        }
        p.moduleTitle {
            font-size: 15px;
            line-height: 21px;
            @media (orientation:portrait) {
                font-weight: 600;
            }
        }
        p.moduleSubtitle {
            font-size: 13px;
            line-height: 18px;
            height: 18px;
        }
    }

    .roadSeparator {
        background-color: $back;
        height: $defaultHeight;
        display: flex !important;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding-top: 62%;
        @media (orientation: portrait) {
            padding-top: 72%;
        }
        .separator {
            width: $separatorWidth;
            height: 4px;
            border-radius: 12px;
            background-color: $green;
            &.incomplete {
                background-color: var(--light-gray);
            }
            @media (orientation: portrait) {
                width: 24px;
                height: 3px;
                margin-top: 40px;
            }
        }
    }
}

.references {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 18px;
    @media (orientation:portrait) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .reference {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 32px;
        @media (orientation: portrait) {
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .circle {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--common-text);
            &.active {
                background-color: $green;
            }
        }
        .referenceText {
            font-size: 12px;
            line-height: 17px;
            margin-left: 8px;
            margin-right: 32px;
            margin-bottom: 0;
            color: var(--disabled);
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
