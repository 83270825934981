.choice-feedback{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 208px;
    margin-left: 30px;
    @media screen and (max-width: 730px) {
        margin-left: 15px;
    }

    &--wrong-image-feedback{
        margin-right: -400px;
        @media screen and (max-width: 730px) {
            margin-right: unset;
        }
    }

    &__icon{
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;
        align-self: flex-start;
        color: var(--success);
        background-color: transparent;
        border-radius: 50%;

        &--wrong{
            color: var(--warning);
        }

        .icon__box--inline{
            height: 12px;
        } 
    }

    &__text{
        width: 510px;
        margin-left: 13px;
        font-family: var(--primary-font);
        font-size: 15px;
        color: var(--disabled);
    }
}