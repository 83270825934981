@import './_variables.scss';

.incomplatible-version{
    height: 100%;
    width: 100%;
    position: absolute;
    backdrop-filter: invert(.3);
    -webkit-backdrop-filter: invert(.3);
    z-index: 9999;
    position: fixed;

    &--hidden{
        display: none;
    }

    &__alert{
        height: 110px;
        width: 100%;
        background-color: var(--primary-dark);
        color:var(--white);
        position: absolute;
        bottom: 0;
        padding: 32px 64px;
        display: flex;
    
        @media (max-width: 768px) {
            bottom: 0;
            display: table;
        }
    
        &--text{
            font-size:15px;
            width: 70%;
            align-items: center;
            display: flex;
            @media (max-width: 768px) {
                width: 100%;
                margin: 20px 0;
            }
        }
    
        &--button{
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
    
            @media (max-width: 768px) {
                width: 100%;
                justify-content: center;
            }
    
            svg{
                margin-left: 30px;
                cursor: pointer;
                @media (max-width: 768px) {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }
    }
}