.GoBackBar {
  display: flex;
  background: var(--white);
  color: var(--primary);
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  box-shadow: var(--darker-box-shadow);
  text-shadow: var(--darker-box-shadow);

  .arrow {
    display: inline-block;
    padding: 14px 10px 10px 20px;
  }

  .text {
    padding: 15px 0px 10px 20px;
  }
}
