$icon-margin-top: -93px;

.quiz-cover {
    padding: 24px 23px 16px 23px;
    min-height: 186px;
    width: 100%;

    &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__left-container{
            display: flex;

            &__image-container{
                &__box{
                    width: 41px;
                    @media screen and (max-width: 730px) {
                        margin-bottom: 20px;
                        height: 42px;
                        width: 30px;
                    }
                }
                @media screen and (max-width: 730px) {
                    margin: $icon-margin-top auto 5px;
                }
            }
    
            &__text{
                margin-left: 16px;
                font-weight: normal;
                text-align: left;
                color: var(--common-text);
    
                &__title{
                    font-family: var(--secondary-font);
                    font-size: 20px;
                    font-weight: 600;
                    @media screen and (max-width: 730px) {
                        font-size: 18px;
                    }
                }
                &__description{
                    font-family: var(--primary-font);
                    font-size: 15px;
                }
                @media screen and (max-width: 730px) {
                    text-align: center;
                }
            }

            &__start-button{
                min-width: 125px;
            }

            @media screen and (max-width: 730px) {
                flex-direction: column;
            }
        }
        @media screen and (max-width: 730px) {
            flex-direction: column;
        }
    }

    &__details {
        font-family: var(--primary-font);
        font-size: 15px;
        text-align: left;
        color: var(--disabled);
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media screen and (max-width: 730px) {
            line-height: 1.38;
            font-size: 13px;
        }
    }

    &__separator{
        border-top: 1px solid  var(--light-gray);
        margin: 24px 0 16px 0;
    }

    &__footer{
        display: flex;
        justify-content: space-around;

        &__start-button{
            max-width: 125px;
            margin-top: 20px;

            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
    }    

    @media screen and (max-width: 730px) {
        margin: 35px 0 54px 0;
        padding: 24px 0 16px 0;
    }
}
