.list-item-panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1.9rem;
    border: thin solid var(--light-gray);
    border-radius: 12px;
    margin: 0.8rem 0;
    background-color: var(--white);

    &__content {
        width: 80%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        &-student {
            width: 45%;
            cursor: default;
        }

        &-date {
            width: 25%;
            font-family: var(--primary-font);
            color: var(--info);
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 2%;
            > p {
                margin: 0;
            }
        }

        &-status {
            -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                    flex-grow: 1;
        }

        @media screen and (max-width: 893px) {
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                width: 70%;

            &-student {
                width: 100%;
            }

            &-date {
                -webkit-box-ordinal-group: 2;
                    -ms-flex-order: 1;
                        order: 1;
            }

            &-date,
            &-status {
                margin-top: 1.5rem;
                width: 40%;
            }
        }
    }

    &__button {
        margin-left: auto;

        @media screen and (max-width: 893px) {
            & :first-child {
                width: 100%;
            }
        }
    }
}
