.grades-list {
    &__table {
        margin-top: 15px;
    }
    &__message-link {
        color: var(--primary);
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0;

        &__icon {
            margin-left: 4px;
            margin-right: 2px;
            position: relative;
            top: 4px;
        }
    }
    &__empty-box {
        padding: 80px 0;
        display: flex;
        justify-content: center;
        color: var(--disabled);
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
}
