.choice-question-container {
    &--image {
        display: grid;
        grid-auto-rows: minmax(37px, auto);
        grid-gap: 23px;
        grid-template-columns: 1fr 1fr;
        
        @media screen and (max-width: 730px) {
            grid-template-columns: 1fr;
        }
    }

}
