.table-responsive {
   table td[class*="col-"],
   table th[class*="col-"] {
    display: inline-block;
   }
}

.truncate {
    white-space: normal;
    text-overflow: ellipsis;
    border-top: none !important;
}