.file-uploader-manager {
    position: relative;
    width: 100%;
    &--disabled {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        backdrop-filter: grayscale(1);
    }

}