.__radioButtonBox {
    display: inline-flex;
    flex-direction: column;
    margin-top: 20px;

    .__radioButtonsGroup {
        display: inline-flex;
        flex-direction: row;
        padding: 0;

        & > li {
            list-style: none;
            border: solid 1px var(--light-gray);
            padding: 28px 28px 26px 28px;
            height: 42px;
            width: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 0;
            cursor: pointer;

            &.active {
                color: var(--white);
            }

            &:first-child {
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
            }

            &:last-child {
                border-bottom-right-radius: 10px;
                border-top-right-radius: 10px;
                border-right: solid 1px var(--common-text);
            }
        }
    }

    ._radioButton_infoBox {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
    }
}
