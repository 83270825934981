$default-height: 22px;
$default-size: $default-height;

@mixin mask-image($value) {
    -webkit-mask-image: $value; /* Chrome, iOS, Safari */
            mask-image: $value; /* None yet / Non-standard */
}

@mixin mask-size($value) {
    -webkit-mask-size: $value; /* Chrome, iOS, Safari */
            mask-size: $value; /* None yet / Non-standard */
}

@mixin mask-position($value) {
    -webkit-mask-position: $value; /* Chrome, iOS, Safari */
            mask-position: $value; /* None yet / Non-standard */
}

.skeleton-container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 5px;

    &__circle,
    &__square,
    &__line {
        background-color: rgba(var(--rgb-black), 0.1);
        display: inline-block;

        @include mask-image(linear-gradient(
            -75deg,
            rgba(var(--rgb-black), 0.6) 30%,
            rgb(var(--rgb-black)) 50%,
            rgba(var(--rgb-black), 0.6) 70%
        ));

        @include mask-size(200%);

        animation: shine 0.9s linear infinite;
    }

    &__line {
        width: 100%;
        height: $default-height;
        border-radius: 5px;
        margin: 5px;
    }

    &__circle {
        width: $default-size;
        height: $default-size;
        border-radius: 50%;
    }

    &__square {
        width: $default-size;
        height: $default-size;
    }

    &--left {
        justify-content: flex-start;
    }

    &--right {
        justify-content: flex-end;
    }

    &--single-line {
        width: 100%;
    }
}

@keyframes shine {
    from {
        @include mask-position(150%);
    }
    to {
        @include mask-position(-50%);
    }
}