.review-activity__panel {
    background-color: var(--white);
    padding: 30px 40px;
    margin-bottom: 8rem;

    &__form--pending {
        opacity: 0.3;
        pointer-events: none;

        #faileActivityBtn,
        #approveActivityBtn {
            pointer-events: none;
        }
    }
}
