.survey-button {
    &--submit {
        margin-left: 20px;
    }
}

._question_box {
    margin: 50px 0;

    @media (orientation: portrait) {
        margin: 30px 0;
    }

    h3 {
        font-size: 16px;
    }

    .form-group {
        margin-bottom: 0 !important;
        margin-top: 33px;
    }

    ._question_info {
        color: var(--common-text);
        // font-weight: 700;
        line-height: 26px;
        font-weight: 300;
        font-size: 16px;

        ._arrow_right {
            vertical-align: middle;
            margin: 0 5px;
            width: 16px;
            height: 16px;
        }
    }

    @media (orientation: portrait) {
        ._question_info {
            font-size: 15px;
            line-height: 23px;
        }

        .form-group {
            margin-top: 17px;
        }
    }
}

._padding_sides_22p {
    padding-left: 21%;
    padding-right: 21%;

    @media (orientation: portrait) {
        padding-left: 0;
        padding-right: 0;

        .__card_title {
            margin-bottom: 0;
        }
    }
}

._radioBox_alert_message {
    padding-left: 20px;
    font-weight: 300;
    color: var(--danger);
    padding-top: 5px;
    display: block;
}

._modal_confirmation_error {
    font-weight: 300;
    color: var(--danger);
    padding-top: 5px;
    display: block;
    font-size: 14px;
    margin-top: 20px;
}

._header_responsive_margin {
    @media (orientation: portrait) {
        margin: 30px 0 40px 0;

        .__main_footer {
            padding: 0;
            width: 100%;

            ._btn_group {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}
