.permission-category_title {
    font-family: var(--primary-font);
    font-size: 17px;
    font-weight: 600;
    color: var(--common-text);
}

.permission-category_description {
    font-family: var(--primary-font);
    font-size: 15px;
    font-weight: normal;
    color: var(--common-text);
}

.permission-category_list {
    margin-left: 0 !important;
}

.permission-category_icon{
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 5px;
}
