@mixin block-content {
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
    display: block;
    float: left;

    @media (orientation: portrait) {
        padding: -0px 0px 0px;
        width: 90%;
        margin: 5%;
    }
}
