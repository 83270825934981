.idle-modal {
    &__title {
        font-family: var(--secondary-font);
        font-weight: 600;
        font-size: 18px;
        text-transform: initial;
        color: rgb(var(--rgb-black));

        &--icon {
            position: relative;
            top: 5px;
            margin-right: 12px;
        }
    }

    &__content {
        width: 55%;
        margin: auto;
        line-height: 25px;
        padding: 10px 0;
        font-size: 15px;
    }
    
}
