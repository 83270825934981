$ph-black: var(--common-text);
$ph-gray: var(--disabled);
$brand: var(--danger);

.avatar-button {
    &--save {
        margin-left: 20px;
    }
}

.__modal.__modal_avatar {
    .modal-dialog {
        width: 760px;
    }
    .__modal_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &.avatar-modal-header {
            padding: 0 !important;
            margin: 0 !important;
            h3 {
                margin: 0;
                padding-left: 38px;
                padding-top: 28px;
                padding-bottom: 17px;
            }
        }
        .close_icon {
            color: var(--disabled);
            display: block;
            margin-left: auto;
            margin-right: 27px;
            margin-top: 29px;
            font-size: 22px !important;
            path {
                stroke:var(--white);
                stroke-width: 45px;
            }
            &:hover {
                color: var(--common-text);
            }
        }
    }
    .__modal_footer.avatar-modal-footer {
        padding-right: 16px !important;
        padding-bottom: 40px !important;
        padding-top: 50px !important;
        padding-left: 0 !important;
        ._btn_group {
            margin-bottom: 0;
        }
    }
    .__modal_body.avatar-modal-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 35px !important;
        padding-bottom: 30px !important;
        padding-left: 103px !important;
        padding-right: 103px !important;


        .avatar-modal-container-row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            width: 100%;
            .invalidAvatar-box {
                width: 100%;
                background-color: var(--danger-light);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px;
                .alert-icon {
                    color: var(--brand);
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                }
                .message {
                    color: var(--common-text);
                    font-size: 13px;
                }
            }
        }

        .avatar-modal-container-column {
            .loadingSpinner {
                position: absolute;
                left: 162px;
                top: 100px;
                font-size: 32px;
                color: rgba(var(--rgb-black),0.8);
                z-index: 9999;
            }

            .modal-avatar {
                width: 110px;
                height: 110px;
                margin-right: 48px;
            }

            .modal-avatar-icon {
                font-size: 110px;
                margin-right: 28px;
                color: var(--disabled);

            }
            .uploadBox {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 354px;
                height: 88px;
                background-color: var(--background);
                margin-top: 20px;
                .uploadIcon {
                    color: var(--primary);
                    font-size: 26px;
                    margin-right: 10px;
                }
                .uploadText {
                    margin: 0;
                    padding: 0;
                    background-color: unset;
                    border: none;
                    font-family: var(--secondary-font);
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 23px;
                    letter-spacing: 0.15px;
                    color: var(--primary);
                    &:hover {
                        text-decoration: none;
                        color: var(--primary);
                    }
                }
            }
        }
    }
    h3 {
        font-family: var(--primary-font);
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 1px;
        color: var(--common-text);
    }

    h4 {
        font-family: var(--primary-font);
        font-size: 18px;
        line-height: 25px;
        color: var(--common-text);
    }

    p {
        font-family: var(--primary-font);
        color: var(--disabled);
        .description {
            font-size: 13px;
            line-height: 18px;
        }
        &.conditionLegend {
            position: relative;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            align-items: center;
            justify-content: center;
            justify-items: center;
            font-size: 15px;
            line-height: 21px;
            color: var(--common-text);
            .highlight {
                color: var(--brand);
            }
            .separator {
                position: absolute;
                border: 1.5px solid grey;
                height: 50px;
                top: 0;
                left: 44%;
            }
        }
    }
}
