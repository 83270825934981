.owner_assign{
    //This !important shorthand has been set because the rest of the PlayGround headers shouldn't change, and for now this component isn't custom
    padding-top: 20px !important;
}

.empty_assign {
    display: grid;
    justify-content: center;
    border-radius: 50%;
    flex-direction: column;
    height: 400px;

    &__image {
        width: 400px;
        opacity: .6;
    }

    &__text {
        opacity: 0.88;
        font-family: var(--secondary-font);
        font-size: 17px;
        text-align: center;
        color: var(--common-text);
        margin-top: 30px;
    }
}

.assign_subtitle {
    margin-top: 15px;
    font-size: 16px;
}

.assign_card {
    border-radius: 15px;
    background: var(--white);
    padding: 20px;
    margin: 30px 0 30px 0;
}

.assign_step {
    border-radius: 16px;
    background-color: var(--success-light);
    padding: 6px 15px 6px 15px;
    font-weight: 600;
    height: 31px;
    display: initial;
}

.assign_card_title {
    color: var(--common-text);
    font-size: 20px;
    font-family: var(--primary-font);
    font-weight: 600;
    margin: 20px 0 10px 0;
}

.assign_card_subtitle {
    color: var(--disabled);
    font-size: 16px;
}

#assign_user_search_button {
    width: 120px;
    margin-top: 20px;
}

.user_search_results {
    margin-top: 30px;

    button svg {
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
}

.assign_loader_area {
    height: 200px;
    text-align: center;
    display: flex;
    align-items: center;
}

.assign_loader_area > svg {
    margin: 0 auto;
}

.assign_modal_body {
    text-align: center;
}

.assign_modal_body > p:first-child {
    font-size: 23px;
    font-weight: bold;
    color: var(--common-text);
}

.assign_modal_body > p:last-child {
    font-size: 15px;
    color: var(--disabled);
}

.assign_users_table{
    max-height: 350px;
    overflow-y: auto;
}

.assign_users_table thead{
    display: none;
}

.assigned_users_table{
    min-height: 350px;
    overflow-y: auto;
}

.assigned_users_table > div {
    min-height: 350px;
}

.assign_users_list{
    font-size: 15px;
    color: var(--common-text);
}

.assign_users_list tr {
    height: 50px !important;
    border-top: solid 1px var(--disabled);

    &:first-child{
        border-top: none;
    }

    &:hover {
        background-color: var(--background);
      }
}

.select_role_td {
    display: block;
}

.assign_users_list td {
    height: 50px !important;
    line-height: 40px !important;
    border: none !important;
}

.pre_assign_user_button{
    border: none;
    background: transparent;
    font-weight: bold;
    color: var(--primary);
}

.remove_preAssign_user {
    background: transparent;
    border:none;
}

.remove_preAssign_user > span{
    color: var(--disabled);
    position: relative;
    top: 5px;
}

.search_by_role{
    width: 300px;
}

._no_show_element{
    display: none !important;
}

.show_empty_msg{
    display: block !important;
    height: 200px;
    line-height: 200px;
    color: var(--disabled);
    text-align: center;
}

.assign_empty_msg{
    display: none;
}

#remove_email_content, #remove_name_content, #remove_surname_content {
    position: absolute;
    right: 20px;
    top: 32px;
    border: none;
    background: transparent;
    color: var(--disabled);
}
