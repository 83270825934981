.role-button {
    margin-left: 20px;
}

._roles_container {
    background-color: var(--white);
    border-radius: 7px;
    border: 1px solid var(--light-gray);
    margin-bottom: 50px;
    overflow: hidden;
    padding: 14px 40px 45px;
    position: relative;
}

._checkbox_label {
    font-weight: 300;
}
