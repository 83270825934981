.rule-modal {
    &__input-container {
        margin-bottom: 20px;

        .input-number {
            max-width: 42%;
            position: relative;
            top: 5px;
        }
    }
}

.tableModalAttributesContainer {
    border: 1px solid var(--light-gray);
    padding: 25px;
    border-radius: 15px;
    margin: 0 0 15px 0;
}
