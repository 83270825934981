.class-progress {
    margin-top: 50px;

    &__box {
        display: flex;
        justify-content: center;
        position: fixed;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        &--input {
            margin-right: 30px;
            width: 155px;
        }

        &--button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__alert {
        margin-bottom: 25px;
    }
}
