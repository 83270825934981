.arrowContainer.right {
    right: 0;
}
.arrowcontainer.left {
    left: 0;
}
.arrowContainer {
    background-color:var(--white);
    height: 117px;
    width: 26px;
    margin: 0;
    z-index: 5;

    .icon.arrow {
        color: var(--primary);
        &:active{
            color: var(--primary-dark);
        }
    }
    
    .icon.arrow.left {
        position: absolute;
        top: 45%;
        left: 25%;
        z-index: 1;
        width: 10px;
    }
    
    .icon.arrow.right {
        position: absolute;
        top: 45%;
        right: 25%;
        width: 10px;
    }


}    
