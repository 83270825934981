@import '../../assets/css/mixins/block-content.scss';

.blockContent {
    @include block-content;

    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .loadingIcon {
        margin-left: 15px;
    }
}

.__hints_chevron {
    margin-left: 10px;
    vertical-align: bottom !important;
}

.playGroundResults {
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 7px;
    font-family: var(--primary-font);

    h3 {
        margin: 0;
        font-weight: 700;
        font-size: 18px;
    }

    ul {
        margin: 15px 0 0;
    }

    &.bottom {
        margin-top: 30px;
    }

    &.top {
        margin-bottom: 30px;
    }

    &.danger {
        background: var(--danger-light);
        color: var(--danger);
        border-color: var(--danger-light);
    }

    &.success {
        color: var(--success);
        background-color: var(--success-light);
        border-color: var(--success-light);
    }
}
