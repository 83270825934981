$mobile: 'max-width: 768px';

.quiz-skeleton {

    margin-bottom: 20px;

    &__buttons{
        z-index: 9999999;
        position: relative;
        top: -100px;
        float: right;
        right: 50px;
        &--item {
            background-color: var(--light-gray);
            height: 36px;
            width: 36px;
            border-radius: 50%;
            display: inline-block;
            margin: 0 10px;
        }
    }
}
