@import './questionCommons';

.question-container {
    max-width: $question-width;
    margin: 0 auto;

    &__question {
        margin: auto $order-box-size + $order-box-margin-right;

        @media screen and (max-width: 730px) {
            margin: auto 0;
        }
    }
}