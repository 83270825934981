.owner-edit-card {
    border-radius: 15px;
    background: var(--white);
    padding: 20px;
    margin: 30px 0;
}

.owner-edit-card_title {
    color: var(--common-text);
    font-family: var(--secondary-font);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-left: 15px;
}

.owner-edit-card_subtitle {
    color: var(--disabled);
    font-family: var(--primary-font);
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;
    padding-left: 15px;
}

.owner-role-selector {
    min-width: 30%;
}

.owner-role-selector .css-15k3avv {
    color: var(--primary);
    text-align: center;
    max-height: 200px;
}
.owner-role-selector > div {
    line-height: 20px;
}

.owner-role-selector .css-15k3avv * {
    max-height: 200px;
}

.owner-role-selector .css-15k3avv > div > div {
    height: 40px;
}

.owner-role-selector .css-15k3avv > div > div:hover {
    background-color: var(--background);
    color: var(--primary);
    font-weight: 600;
}

.owner-role-selector .css-15k3avv{
    color: var(--primary);
    text-align: center;
    max-height: 150px !important;
}
.owner-role-selector > div {
    line-height: 20px;
}

.owner-role-selector .css-15k3avv * {
    max-height: 150px !important;
}

.owner-role-selector .css-15k3avv > div > div{
    min-height: 40px;
    font-weight: 600;
}

.owner-role-selector .css-15k3avv > div> div:hover{
    background-color: var(--background);
    color: var(--primary);
}
