.confirmMessageMobile {
  background:var(--white);
  height: 89vh;
  margin: 0px !important;
  padding: 0px 0px 0px 0px !important;
  color: var(--disabled);

  @media (orientation: landscape) {
    background: none;
    padding: 0px 0px 0px 0px !important;
    .goBackBarWrapper {
      display: none;
    }
    .contentWrapper{
        background:var(--white);
        width:50%;
        margin: 10% 25% 0% 25%;
        padding:2%;
        box-sizing: 0px 0px 10px var(--common-text);
        border-radius: 10px;
        .content{
          padding: 20px 20px 20px 20px;
          .icon {
            width: 70px;
            height: 70px;
          }
        }
      }
  }

  .content {
    display: block;
    width: 100%;
    text-align: center;
    align-content: center;
    padding: 140px 20px 20px 20px;

    .icon {
      display: block;
      margin: auto auto 20px auto;
      font-size:50px;

      svg{
        height: 50px;
        width: 50px;
        color: var(--success-light);
      }
    }
    .title {
      margin-top: 20px;
      display: block;
      font-size: 21px;
      font-weight: bold;
    }
    .message {
      display: block;
      font-size: 21px;
      font-weight: 50;
    }
  }

  .verticalButtonGroup {
    align-content: center;
    text-align: center;

    %button {
      display: block;
      margin: auto;
      padding: 3px;
      width: 138px;
      height: 26px;
      border-radius: 50px;
      margin-bottom: 10px;
      font-family: var(--secondary-font);
      font-weight: 500;
      cursor: pointer;
    }

    .greenButton {
      @extend %button;
      background: var(--primary-light);
      color:var(--white) !important;

      &:hover {
        background: var(--primary-light);
      }   
    }

    .normalButton {
      @extend %button;
      background: none;
      border: 1px solid var(--primary-light);
      color: var(--primary-light);

      &:hover {
        background: var(--primary-light);
        color:var(--white);
        border: none;
      }   
    }
  }
}
