.evaluate-modal-message {
    color: var(--common-text);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.02em;

    > * {
        margin: 0;
    }

    &__student {
        font-weight: 800;
        font-size: 24px;
        margin: 0.2rem 0;
    }
}