.headerIconBox {
    display: inline;
    margin-right: 12px;

    .exerciseHeaderIcon {
        border-radius: 50%;
        width: 25px;
        height: 25px;
    }
}

.exerciseHeader {
    h2 {
        color: var(--common-text);
        font-family: var(--secondary-font);
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.03px;
        line-height: 26px;
        text-align: left;
        margin-top: 0;
    }

    .playgroundTitle {
        display: flex;
        flex-direction: row;
        padding-top: 2px;
        font-family: var(--secondary-font);
    }

    .statementDescription {
        width: 100%;
        padding: 20px 0;
        float: left;
        display: block;

        iframe {
            width: 100%;
            display: block;
        }
    }

    .__hints_container {
        border-radius: 7px;
        width: 100%;
        position: relative;
        font-family: var(--primary-font);
        font-weight: 300;

        .blockHints {
            background-color: var(--background);
            border-radius: 7px;
            width: 100%;
            padding: 20px 25px !important;
            font-family: var(--primary-font);
            font-weight: 300;
            float: right;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: relative;
            margin-bottom: 15px;
            margin-top: -5px;

            .arrow {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 25px;
                height: 100%;
                border: 1px solid var(--info);
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--info);
                cursor: pointer;
            }

            .leftArrow {
                left: 0;
            }

            .rightArrow {
                right: 0;
            }
        }
    }
}
