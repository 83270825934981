@import './questionCommons';

.question-header {
    display: flex;
    align-items: center;
    margin: 32px auto;

    &__order {
        width: $order-box-size;
        height: $order-box-size;

        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        
        background-color: $order-background;
        color: $quiz-font-color;
        
        font-family: var(--primary-font);
        font-size: 24px;
        font-weight: 600;
        margin-right: $order-box-margin-right;

        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @media screen and (max-width: 730px) {
            position: absolute;
        }
    }

    &__title {
        color: $quiz-font-color;
        
        text-align: left;

        font-family: var(--primary-font);
        font-size: 15px;

        @media screen and (max-width: 730px) {
            margin-left: 50px;
        }
    }
}
