.exercise-results__box {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 768px) {
        width: 750px;
    }
    @media (min-width: 992px) {
        max-width: 80rem;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .--create-exercise-icon {
            font-size: 28px;
        }

        .create-exercise {
            width: 300px;
        }
    }
    &__main {
        display: flex;
        flex-direction: column;
    }
    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 44px;
    }
}
