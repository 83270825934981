.addNewPasswordFormMobile {
  padding: 0px 0px 50px 0px;
  background: var(--white);
  height: 100vh;
  text-align: center;

  .form {
    padding: 42px 37px 75px;

    .form-group{
        font-family: var(--primary-font);

      .control-label {
        font-size: 18px;
        color: var(--disabled);
        font-family: var(--primary-font);
        padding-left: 0;
      }

      .form-control {
        border: solid 0.5px var(--light-gray);
        background: var(--white);
        display: inline-block !important;
        height: 30px !important;
        margin-top: 20px;
        color: var(--disabled);
        padding: 0 16px;
        &::placeholder {
            font-size: 14px;
            font-family: var(--primary-font);
        }
      }
    }

    .centerLoginButton{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 120px;
    }
  }
}
