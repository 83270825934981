.users-search {
    border-right: 1px solid var(--background);
    height: 59px;
    margin-top: 1px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    background-color: var(--white);
    &__input {
        .input-text {
            &::placeholder {
                font-size: 14px;
            }
        }
    }
}
