.studentVisibility {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    
    label {
        padding-left: 15px;
    }
}