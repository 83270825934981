.onboarding-modal {
    background-color: var(--primary-dark);
    border-radius: 12px;
    padding: 30px;
    width: 600px;
    padding: 40px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    &__background {
        opacity: 0.9;
        top: 0;
        left: 0;
        position: absolute;
        filter: hue-rotate(330deg) contrast(60%);
    }

    &__title {
        font-family: var(--secondary-font);
        font-size: 22px;
        margin: 0;
        font-weight: 600;
    }

    &__text {
        font-family: var(--primary-font);
        font-size: 16px;
        margin: 0;
        width: 75%;
        font-weight: 600;
    }

    &__title,
    &__text:not(:first-of-type) {
        margin-bottom: 20px;
    }

    &__button#StartOnBoardingBtn {
        margin-top: 20px;
        &:hover {
            box-shadow: unset;
            border-color: unset;
        }
    }
}
