@mixin linkStyles {
    color: var(--primary);
    &:hover {
        text-decoration: none;
        color: var(--primary-dark);
    }
}

.auth-page {
    width: 100%;
    min-height: 100vh;

    padding-top: 3rem;
    padding-bottom: 3rem;

    padding: 0px;

    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    height: auto;
    justify-content: center;

    @media (min-width: 1024px) {
        justify-content: space-between;
        flex-direction: row;
        padding-left: 5.5rem;
        padding-right: 5.5rem;
    }

    @media (min-width: 640px) {
    }

    &__signature {
        a {
            width: 125px;
        }
        @-moz-document url-prefix() {
            margin-right: 15px;
        }

        @media (orientation: portrait) {
            justify-content: center;
        }
        @media (max-width: 350px) {
            flex-direction: column;
            margin-right: 0;
            text-align: center;
        }
    }

    &__welcome-msg {
        color: var(--white);
        font-family: var(--secondary-font);
        font-weight: 600;
        font-size: 40px;
        line-height: 6rem;

        @media (orientation: portrait) {
            font-size: 22px;
            margin-top: 20px;
        }
    }

    &__message-box {
        margin-bottom: 15px;
    }

    &__card {
        background: var(--white);
        padding-top: 3rem;
        padding-bottom: 3rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        border-radius: 5px;

        @media (min-width: 640px) {
            padding-left: 2rem;
            padding-right: 2rem;
            max-width: 440px;
            width: 100%;
        }

        &__pg-logo {
            width: 80%;
            margin: 0 auto 20px;

            img {
                width: 100%;
            }
        }

        &__dh-logo {
            width: 50%;
            margin: 0 auto 20px;
        }

        &__input-box {
            margin-bottom: 20px;

            &--username {
                p {
                    font-size: 14px;
                }
            }
        }

        &__links {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            @media (max-width: 378px) {
                flex-direction: column;
                align-items: center;
            }

            a {
                @include linkStyles;
                font-size: 12px;
                @media (max-width: 378px) {
                    font-size: 13px;
                    &:nth-child(even) {
                        margin-top: 5px;
                    }
                }
            }

            a:nth-child(even)::before {
                content: '-';
                margin: 0 5px;

                @media (max-width: 378px) {
                    content: unset;
                }
            }
        }
    }

    &__button {
        margin-bottom: 15px;
        
        &--link {
            @include linkStyles;
            margin-bottom: 0;
            font-size: 13px;
        }
        
        &--strategy {
            border: 2px solid var(--light-gray) !important;
            color: var(--common-text) !important;

            &--logo {
                width: 25px;
                height: 25px;
                margin-right: 10px;
                @media (orientation: portrait) {
                    margin-left: 5px;
                }
            }

            @media (orientation: portrait) {
                font-size: 12px;
            }

            &:last-child {
                margin-bottom: 30px;
            }
        }
    }

    &__confirm-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;

        & &--label {
            margin: unset;
            color: unset;
            margin-left: 5px;
            cursor: pointer;
            color: var(--common-text);
            font-weight: 600;
            width: 77%;
            font-size: 13px;
        }

        & &--checkbox {
            transform: scale(0.7);
        }
    }
}
