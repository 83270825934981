@import './classroomCommons';

.classroom-skeleton {
    margin-top: 20px;
}
.classroom-breadcrumb{
    margin-top:70px;
    padding: 30px 30px 20px;

    @media(min-width: $desktop-break-point) {
        padding-top: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.ownership-message {
    padding: 0 10px;

    &--top {
        margin-top:70px;
    }

    @media(min-width: $desktop-break-point) {
        padding-bottom: 10px;
        min-height: unset;
        margin: 0 auto;
        margin-top: 10px;
        grid-template-columns: fit-content(400px) 3fr;
    }
}

.classroom-container {
    display: grid;
    grid-auto-rows: minmax(37px, auto);
    grid-gap: 10px;
    grid-template-columns: 1fr;

    padding: 10px 0;

    &--fix-min-height {
        min-height: 100vh;
    }
    
    @media(min-width: $desktop-break-point) {
        padding-bottom: 10px;
        min-height: unset;
        margin: 0 auto;
        grid-template-columns: fit-content(400px) 3fr;
    }

    @media (max-width: 820px) {
        .no-connection__block-title {
            display: none;
        }

        .no-connection-empty-state {
            padding: 5% 15%;
        }
    }
}

.white-container {
    background-color:var(--white);
    border-radius: 7px;
    width: 100%;
}