
@import './variables';

$animDelay: 0.25s;
$animDuration: 0.25s;

.popover.right > .arrow:after {
    border-right-color: $ph-darkblue !important;
}

#onboardingSidePopover {
    background-color: $ph-darkblue;
    border-radius: 12px;
    position: fixed;
    z-index: 2000 !important;
    max-width: 293px !important;
    top: 20vh;
    opacity: 0.0;
    animation-name: popOverEffect;
    animation-duration: $animDuration;
    animation-delay: $animDelay;
    animation-fill-mode: forwards;
    display:none;
    
}

@keyframes popOverEffect {
    0% {opacity: 0.0;}
    100% {opacity: 1.0;}
    
}


.popover-content {
    padding: 0 !important;
}

.onboardingPopoverHeader{
    padding-right: 5px;
    padding-top: 13px;
}

.onboardingPopoverBody .onboardingSideGif {
    width: 290px !important;
    height: 267px !important;
    border-top: 0px solidvar(--white);
}

#onboardingSidePopover .onboardingPopoverBody .paragraph {
    color:var(--white);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.3px;
    padding: 12px 12px 0;
    text-align: left;
}

.menuBackCinema {
    z-index: 1005;
    position: fixed !important;
    top:0px !important;
    left: 0px !important;
    height: 100vh !important;
    width: 100vw !important;
    overflow: hidden !important;
    background-color:var(--common-text) !important;
    opacity: 0.0;
    animation-name: backEffect;
    animation-duration: $animDuration;
    animation-delay: $animDelay;
    animation-fill-mode: forwards;
    visibility:hidden;
}
@keyframes backEffect {
    0% {opacity: 0.0;}
    100% {opacity: 0.5;visibility: visible;}
}

.menuOverCinema.disabledPointer {
    z-index: 2000;
    position: fixed !important;
    top:140px !important;
    left: 0px !important;
    height: 100vh !important;
    width: 100vw !important;
    overflow: hidden !important;
    background-color: none !important;
}

.progressPanel .__left_sticky{
    position: relative !important;
    top: 0px !important;
    z-index: 2000 ;   
}
