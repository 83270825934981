.toolPanel {
    min-height: 84px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    box-shadow: var(--darker-box-shadow) !important;
    word-break: break-word !important;

    &__name {
        margin: 15px;
        font-size: 21px;
        font-weight: 600;
        color: var(--common-text);
        font-family: var(--secondary-font);
    }

    &__buttons {
        margin: 0 15px !important;
    }
}
