.topic-progress-container {

    &:nth-child(1) {
        margin-top: 16px;
    }

    width: 100%;
    list-style: none;

    > button > * {
        display: flex;
        align-items: center;
    }

    &__item {
        align-items: center;
        color: var(--common-text);
        display: flex;
        font-weight: lighter;
        min-height: 35px;
        padding: 0;
    }

    &__title{
        text-align: left;

        &--active{
            font-weight: bold;
        }
    }
}
