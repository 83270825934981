.unit-nav-container {
    display: flex;
    justify-content: space-around;
    width: 100%;

    &__name {
        color: var(--common-text);
        font-family: var(--primary-font);
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        letter-spacing: normal;
        line-height: 1.39;
        margin-top: 12px;
        padding: 0 40px;
        position: relative;
        text-align: center;
    }

    &__prev-button {
        margin-left: 16px;
        color: var(--common-text);
        &__icon {
            margin-right: 4px;
            vertical-align: bottom;
            width: 20px;
            height: 20px;
        }
    }

    &__next-button {
        margin-right: 16px;
        color: var(--common-text);
        &__icon {
            margin-left: 4px;
            vertical-align: bottom;
            width: 20px;
            height: 20px;
        }
    }

    &__next-button,
    &__prev-button {
        &:not(&--disabled) {
            min-width: 32px;
            display: flex;
            width: 10px;
            justify-content: center;
            align-items: center;
            height: 32px;

            border-radius: 50%;

            color: var(--common-text);

            -webkit-box-shadow: 0 3px 6px 0 rgba(var(--rgb-black), 0.07);
            -moz-box-shadow: 0 3px 6px 0 rgba(var(--rgb-black), 0.07);
            box-shadow: 0 3px 6px 0 rgba(var(--rgb-black), 0.07);

            background-color: rgba(var(--rgb-black), 0);

            transition: background-color 0.2s ease-in-out;

            &:hover {
                color: var(--primary);
                -webkit-box-shadow: 0 3px 6px 0 rgba(var(--rgb-black), 0.25);
                -moz-box-shadow: 0 3px 6px 0 rgba(var(--rgb-black), 0.25);
                box-shadow: 0 3px 6px 0 rgba(var(--rgb-black), 0.25);
            }

            &:active {
                background-color: var(--light-gray);
            }
        }

        &--disabled {
            opacity: 0;
            cursor: auto;
        }
    }
}
