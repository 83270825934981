.course-schedule {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    border-radius: 7px;
    border: 1px solid var(--light-gray);
    height: 133px;
    width: 100%;
    margin-bottom: 16px;

    &-title {
        position: absolute;
        left: -1px;
        top: 0;
        background-color: var(--light-gray);
        border-top-left-radius: 7px;
        border-bottom-right-radius: 7px;

        span {
            padding-left: 21px;
            padding-right: 21px;
            padding-top: 2px;
            padding-bottom: 2px;
            font-family: var(--primary-font);
            font-size: 15px;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    &-main {
        width: 100%;
        position: relative;
        padding-top: 39px;
        padding-left: 22px;
        padding-right: 22px;
        margin-bottom: 15px;
        display: grid;
        grid-template-columns: 1fr .2fr 2fr .2fr 1fr;
        &-item {
            display: flex;
            flex-direction: column;
            padding-right: 10px;
            justify-content: flex-start;
            div {
                color: var(--disabled);
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                &-icon {
                    width: 24px;
                    
                }
                
                p {
                    margin-left: 10px;
                    margin-top: 3px;
                    margin-bottom: 0;
                }
            }
            
        }
    }
}
