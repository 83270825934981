.__dot {
    height: 15px;
    width: 15px;
    background-color: var(--light-gray);
    border-radius: 50%;
    display: inline-block;
}

.__reload_progress_spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 20;

    .__progress_spinner {
        color: var(--light-gray);
        width: 100px;
        height: 100px;
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

.__flex_item .headerIconBox {
    margin-right: 0;
}
