.mobile-content-container {
    text-align: center;

    &__text {
        color: var(--primary);
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 18px;
        text-align: center;
    }

    &__image {
        width: 200px;
        margin: 0 auto;
    }
}
