.ChangePassword {
  padding: 20% 10%;
  background:var(--white);
  height: 100vh;
}

@media only screen and (orientation: landscape) {
  .ChangePassword {
    padding: 10% 20%;
    background: none;
    height: auto;
  }
}

/////////////////////////

.animateComponent{
  visibility: hidden;
  opacity: 0 !important;
  transition: all 0.2s ease-out;

  &.show{
    visibility: visible;
    opacity: 1 !important;
    transition: all 0.2s ease-in !important;
  }
}

.changePasswordContainer {

    .CustomCargando {
        height: 80vh;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            font-size: 25px;
            margin-right: 8px;
            position: relative;
            top: 4px;
        }

        h5 {
            font-size: 14px;
            color: var(--common-text);
        }
    }

    .confirmMessageMobile {

        .content {
            padding: 104px 75px 26px 75px;

            .icon {
                font-size: 36px;
            }
        }

        .title {
            margin-top: 19px;
            font-family: var(--primary-font);
        }

        .message {
            font-size: 20px;
            font-family: var(--primary-font);
            margin-top: 3px;
        }

        .verticalButtonGroup {

            .greenButton {
                font-size: 12px;
                line-height: 20px;
                // font-family: Poppins;
                // font-weight: 500;
                margin-bottom: 18px;
            }

            .normalButton {
                font-size: 12px;
                // font-family: Poppins;
                // font-weight: 500;
                margin-bottom: 18px;
                line-height: 18px;
            }

        }
    }
}
