.presence {
    display: flex;
    align-items: center;
    &__divider {
        height: 40px;
        border-right: 1px solid var(--light-gray);
        margin-right: 20px;
    }
    &__item {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        color: var(--common-text);
    }
    &__item-value {
        font-size: 24px;
        font-weight: bold;
    }
    &__item-label {
        font-size: 12px;
        text-transform: uppercase;
        margin: 0;
    }
    &__value {
        font-weight: bold;
        font-size: 24px;
        color: var(--warning);
        &--success {
            color: var(--success);
        }
    }
    &__icon {
        margin-right: 8px;
    }
}
