@import '../classroomCommons';

.topic-container {
    grid-column: 1;
    max-width: 100vw;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    margin: $toolbar-height -10px 0;

    @media screen and (min-width: $desktop-break-point) {
        max-width: calc(100vw - 20px);
        margin: 0 0 auto;
        background-color: unset;
        grid-column: 2;
        grid-row: 1;
    }

    &__skeleton {
        background-color: var(--white);
        margin: 10px 0;
        border-radius: 8px;

        @media screen and (min-width: $desktop-break-point) {
            padding: 24px 36px;
            border: 1px solid var(--light-gray);
        }
    }

    &__content-container {
        &.slick-slider {
            .slick-list {
                .slick-track {
                    align-items: unset;
                    .slick-slide {
                        padding: 14px;
                    }
                }
            }
        }

        background-color: transparent; //var(--white);
        border: none;

        @media screen and (min-width: $desktop-break-point) {
            display: flex;
            flex-direction: column;
        }
    }

    .pg-block-title {
        h1 {
            color: var(--common-text);
            font-family: var(--secondary-font);
            font-size: 22px;
            font-weight: 700;
            line-height: 33px;
            margin-bottom: 20px;
            margin-top: 0;
        }
    }
}
