$blue: var(--primary);

.crumbs {
    .link {
        font-family: var(--primary-font);
        font-size: 14px;
        line-height: 20px;
        color: $blue;
        font-weight: 700;
        padding: 0;
        text-decoration: none;
        .button__text {
            vertical-align: baseline;
        }
    }

    .ultimo {
        font-family: var(--primary-font);
        font-size: 14px;
        line-height: 20px;
        color: var(--common-text);
        text-transform: capitalize;
        vertical-align: bottom;
    }

    .icon.separator {
        vertical-align: middle;
        border-top: none;
        color: $blue;
        margin-left: 2px;
        margin-right: 2px;
        width: 16px;
        height: 16px;
    }
}
