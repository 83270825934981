.course-card-skeleton {
    width: 280px;
    height: 480px;
    background-color: var(--white);
    border: solid 1px var(--background);
    border-radius: 8px;
    position: relative;
    padding: 20px 0;

    &:not(:last-child) {
        margin-right: 30px;
    }

    & > :first-child {
        margin-bottom: 20px;
    }

    & > :last-child {
        margin-top: 100px;
    }
}
