.__progress_table {
    header {
        display: inline-block;
        background-color: var(--white);
        vertical-align: center;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 3;
    }

    .__report_table_head {
        border: 0;
        border-bottom: 2px solid var(--background);

        .__report_table_row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;

            .__report_table_header {
                background-color: var(--white);
                padding: 5px;
                vertical-align: center;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                z-index: 3;
                order: 0;
                flex: 0 1 auto;
                align-self: auto;
            }
        }
    }

    .__report_table_body {
        display: inline-block;

        .__report_table_row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;

            .__report_table_data {
                padding: 5px;
                vertical-align: center;
                text-align: left;
                order: 0;
                flex: 0 1 auto;
                align-self: auto;
            }
        }

        .__report_table_row:hover {
            box-shadow: var(--common-box-shadow);
        }
    }
}

.__pinned {
    background-color: var(--white);
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    width: 5vw;
    min-width: 120px;
}

.__corner {
    left: 0;
    z-index: 4 !important;
    height: 40px;
    width: 5vw;
    min-width: 120px;
}

._scroll_x {
    overflow: scroll !important;
}

._scroll_prework {
    overflow: auto !important;
}

.__progress_title_prework {
    padding: 0px 0px 10px 0px;
}

.__prework_title_report {
    padding: 18px 0px 5px 0px;
}

.__table_heigth {
    max-height: 500px;
}

.__table_container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
}

.__table_item {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.__listReport {
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        float: left;
        padding: 5px;
    }
}

._ul_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    ._li_items {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
    }
}

._loading_progress {
    opacity: 0.5;
}
