.delete-block-confirmation-modal {
    .block-name {
        color: var(--common-text);
        font-style: italic;
        font-size: 20px;
        margin: 30px 0;
    }
    .modal-dialog {
        @media (min-width: 768px) {
            width: 650px;
        }
    }
}
