$ph-darkblue: var(--primary-dark);

.__information_box.__userDetail {
    background-color: $ph-darkblue !important;
    .__label_box{
        .__avatar_box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid var(--white);
            padding-bottom: 14px;
            .loadingSpinner {
                position: absolute;
                left: 75px;
            }
            .changeAvatarButton {
                padding-left: 24px;
                padding-right: 24px;
                
            }
            .avatar {
                width: 78px;
                height: 78px;
                margin-right: 8px;
            }
        }
        .__label {
            font-family: var(--primary-font) !important;
            &.title {
                font-weight: bold !important;
            }
        }
    }    
}
