/*GENERAL*/

._report_filters > div {
    background-color: var(--common-text);
    color: var(--white);
    text-align: center;
    padding: 5px;
    border-bottom: solid 0.5px var(--background);
}

._report_content {
    margin-top: 30px;
    top: 0px;
    z-index: 999;
    position: inherit;
}

._report_data {
    padding: 0 !important;
    margin-left: -15px;
}

._report_general_button {
    background: transparent;
    border: none;
    padding: 10px 8px;

    svg{
        height: 18px;
        width: 18px;
    }

    button.button--clear {
        width: 100%;
    }
}

._report_inactive_button {
    background: transparent;
    border: none;
    vertical-align: sub;
}

._report_inactive_button span {
    display: inline-flex;
}

._report_regular_button {
    font-weight: normal;
}

._no_show_element {
    display: none;
}

._report_col {
    display: inline-block;
    text-align: center;
    background: var(--white);
    line-height: 16px;
    font-size: 14px;
    font-weight: 600;
    color: var(--common-text);
}

._report_col_block{
    margin-top: 1px;
}

._disable_col {
    pointer-events: none;
    color: var(--light-gray);
}

._no_click_col {
    pointer-events: none;
}

._extra_content_icon{
    height: 43px;
    width: 100%;
    position: relative;
    top: 5px;
}

._extra_content_icon svg{
    color: var(--light-gray);
    align-self: center;
}

._report_students_list [class*="icon"] {
    float: right !important;
}

._report_background {
    position: absolute;
    background-size: 135px 43px;
    background-image: linear-gradient(to right, var(--light-gray) 1px, transparent 1px),
        linear-gradient(to bottom, var(--light-gray) 1px, var(--white) 1px);
    width: 100%;
    height: 100%;
    z-index: -1;
}

._report_background_topics {
    background-size: 100px 99px !important;
    background-image: linear-gradient(to right, var(--light-gray) 1px, transparent 1px),
        linear-gradient(to bottom, var(--light-gray) 1px, var(--white) 1px)!important;
    width: 100%;
    z-index: -1;
}

._report_background_blocks {
    background-size: 50px 43px;
}

._report_filling_content {
    background: var(--white);
    height: 75px;
    border-bottom: solid 0.5px var(--light-gray);
}

/* INFO */

._report_general_info{
    display: inline-flex;
    position: relative;
    width: 100%;
}

._report_general_info .status-card{
    max-width: 250px;
    margin-right: 20px;
}

._report_course_title {
    font-size: 18px;
    color: var(--common-text);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
}

._report_course_alias {
    font-weight: 500;
    display: block;
}

._report_reference_table {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

._report_ref_image {
    border-radius: 8px;
    border: solid 1px var(--light-gray);
    min-height: 230px;
    background: var(--white);
    margin-left: -3px;
}

._report_actions{
    margin-left: auto;
    margin-right: 0;
}

._report_refresh{
    vertical-align: text-top;
}

._report_refresh_loading{
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

._report_ref_image > img {
    min-height: 230px;
    width: 100%;
}

._report_ref_image {
    position: relative !important;
    top: -75px !important;
}

._reference_button_open {
    display: block;
    z-index: 999;
    position: absolute;
    right: 0px;
    top: -10px;
}

._reference_button_open > button > [class*="icon"] {
    transform: rotate(90deg);
}

#show_ref_image [class*="icon"] {
    margin-top: 0px;
}

/* FILTERS */

._report_options_filters {
    display: flex;
}

._report_option_selected,
._report_options_filters:hover,
._report_filters_course > button:hover {
    background: var(--background);
    font-weight: bold;
}

._report_filters_status {
    position: absolute;
    width: calc(100% - 30px);
    background: var(--white) !important;
    color: var(--common-text) !important;
    font-size: 14px;
    border-radius: 6px;
    box-shadow: rgb(var(--darker-box-shadow));
    border: solid 0.5px var(--light-gray);
    height: 120px !important;
    display: grid;
    z-index: 999;
    padding: 0px !important;
}

._report_filters_status > button,
._report_filters_course > button {
    text-align: left;
    width: 100% !important;
}

._report_collapse_enable{
    background: var(--primary) !important;
}

._report_collapse_disabled{
    background: var(--common-text);
}

._report_search {
    height: 50px;
    color: var(--common-text);
}

._report_search > input {
    background: var(--light-gray);
    border-radius: 100px;
    border: 1px solid var(--disabled);
    color: var(--common-text);
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    font-family: 'FontAwesome', sans-serif;
    margin-top: 5px;
    padding: 5px 15px 5px 15px;
}

._report_search > ul {
    color: var(--common-text);
    font-size: 14px;
    width: 90%;
    border-radius: 6px;
    box-shadow: var(--darker-box-shadow);
    border-bottom: none !important;
    max-height: 350px;
}

._report_search > ul > li[style*='background-color: dodgerblue'] {
    background-color: var(--background) !important;
    color: var(--common-text) !important;
}

._report_search > input:focus {
    background: var(--background);
    outline: 0 !important;
}

._report_filter_selected > div {
    background: var(--disabled) !important;
    border-radius: 5px;
    border: none !important;
}

._report_filter_by_state {
    font-size: 14px;
    font-weight: bold;
    height: 50px !important;
    border: none;

    svg{
        height: 18px;
        width: 18px;
    }
}

._report_remove_filter:hover {
    background: var(--disabled);
}

._report_apply_filters {
    text-align: left;
    width: 85%;
}

/* TABS */

._report_tabs {
    background: var(--background);
}

._report_tabs > .nav {
    margin-bottom: 0px !important;
}

._report_tabs li {
    font-size: 16px;
    font-weight: bold;
}

._report_tabs a {
    border-top-right-radius: 8px !important;
    height: 50px;
    color: var(--common-text) !important;
    border: solid 0.5px var(--light-gray) !important;
    border-bottom: none !important;
    width: 120px;
}

/* UNIT LIST */

._report_select_course {
    height: 51px;
    background: var(--white);
    color: var(--common-text);
    font-size: 16px;
    font-weight: bold;
    border: solid 0.5px var(--light-gray);
    display: flex;
}

._report_select_course > div {
    float: left;
    max-width: calc(100% - 118px);
}

._report_select_course button {
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
}

._report_select_course button span:first-child, ._report_filters_course span:first-child  {
    font-family: Poppins;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

._report_select_course [class*="icon"] {
    min-width: 15px;
    align-self: last baseline;
}

._report_filters_course{
    background: var(--white) !important;
    color: var(--common-text) !important;
    font-size: 16px;
    border-radius: 6px;
    box-shadow: var(--darker-box-shadow);
    border: solid 0.5px var(--light-gray);
    max-height: 200px !important;
    max-width: 400px;
    display: grid;
    overflow-y: auto;
    font-family: Poppins;
    z-index: 999;
    font-weight: normal;
    width: 100%;
    position: relative;
}

._report_filters_course:first-of-type{
    min-width: 350px;
    overflow-x: hidden;
}

#_report_select_lesson>button{
    padding-right: 15px;
}

._report_list_lessons{
    width: 100%;
}

._report_filters_to_right {
    margin-left: 119px;
}

#_report_select_default button{
   width: 118px;
}

#_report_select_default > button:first-of-type{
    text-align: center;
}

#_report_select_default>button:first-of-type>span:first-of-type{
    width: 115px;
}

#_report_select_unit {
    max-width: 400px;
}

._report_list_topics{
    max-width: none;
}

._report_select_course button:hover,
._report_select_course button:focus {
    background: var(--background);
}

/* HEADER **/

#_report_detail_cols {
    height: auto;
}

._report_lesson {
    display: flex;
    justify-content: center;

    &_num {
        font-weight: normal;
        font-style: oblique;
        margin-right: 3px;
    }
}

._report_cols::-webkit-scrollbar {
    display: none;
}

._report_cols {
    overflow: hidden;
    white-space: nowrap;
    width: 92%;
    display: inline-block;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    margin-bottom: -5px;
    background: var(--white);
    height: 100px;
    max-height: 175px;
}

._report_cols > ._report_col {
    height: 50px;
}

._report_cols > div:first-of-type {
    height: 99px;
}

._report_units {
    height: 100px;
    border-right: 0.5px solid var(--light-gray);
}

._report_unit_detail {
    width: 135px;
}

._report_block_header {
    width: auto;
    border-right: solid 1px var(--common-text);
}

._report_units > button {
    width: 100%;
}

._report_units p {
    line-height: 20px;
    margin: 0px;
}

._report_units:hover {
    background: var(--light-gray);
    cursor: pointer;
}

._report_units > button p:last-child {
    padding-bottom: 5px;
    height: 65px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

._report_topic_name p {
    overflow: hidden;
    margin-bottom: 2px;
    white-space: break-spaces;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

._report_block_name svg {
    float: right;
    margin-right: -5px;
    margin-top: -15px;
    height: 16px;
    width: 16px;
    color: var(--light-gray);
}

._report_topic_name p:first-of-type {
    font-size: 13px;
}

._report_block_extra{
    background: var(--common-text) !important;
}

._report_units > button p svg {
    float: right;
}

._report_block_name {
    height: 50px;
    width: 45px;
}
._report_block_name div {
    overflow: hidden;
    line-height: 12px;
    font-size: 10px;
    width: 45px;
    margin-left: -8px;
    padding: 0px;
    text-overflow: ellipsis;
    word-break: break-all;
}

._report_block_completed {
    height: 25px;
    line-height: 25px;
    border-top: solid 0.5px var(--light-gray);
    font-size: 12px;
}

._report_block_percent {
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    border-top: solid 0.5px var(--light-gray);
    margin: -5px;
    background: var(--light-gray);
    color: var(--common-text);
}

._report_block {
    width: 50px;
    float: left;
    font-weight: 600;
    border-right: solid 1px var(--light-gray);
}

._report_block_data {
    padding: 0 15px 0 15px;
}

._report_blocks_container {
    height: 75px;
    background-color: var(--common-text);
    color: var(--white);
    text-align: center;
    display: inline-flex;
}

._report_blocks_container div:last-of-type{
    border-color:var(--common-text);
}

._report_block_data {
    color: var(--white);
    text-align: center;
}

._report_block_title {
    line-height: 45px;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    margin: 0px -15px;
}

._report_border_right {
    border-right: solid 0.5px var(--light-gray);
    height: 100%;
}

._report_percent_detail {
    font-size: 12px;
    width: calc(25% - 22px) !important;
}

._report_block_icon {
    width: 50px;
    display: inline-block;
}

._report_block_tooltip p {
    margin-bottom: 2px;
    font-size: 11px;
}

._report_block_tooltip p:nth-last-child(-n + 2) {
    font-weight: 600;
}

/*CONTROL*/
._report_slide_control {
    width: 8%;
    float: right;
    display: inline;
    background: var(--white);
    border-right: solid 0.5px var(--light-gray);
    border-left: solid 0.5px var(--light-gray);
}

._report_slide_control_buttons {
    height: 100px;
    border-bottom: solid 0.5px var(--light-gray);
    text-align: center;
    /* cursor: pointer; */
    background: var(--white);
}

._report_slide_control_buttons > button {
    background: transparent;
    border: none;
    margin: 5px 0 5px 0;
    cursor: pointer;
}

._report_slide_control_buttons p {
    font-size: 11px;
    color: var(--common-text);
    height: 43px;
}

._report_slide_control_buttons svg {
    width: 20px;
    height: 20px;
}

._report_disable_button {
    opacity: 0.5;
    pointer-events: none;
}

/* STUDENTS*/

._report_content_detail {
    border-bottom: 0.5px solid var(--light-gray);
}

._report_course_student_button {
    padding: 10px;
    width: 100%;
    text-align: justify;
    height: 43px;
}

._open_arrow {
    transform: rotate(180deg);
}

._report_student_detail {
    font-size: 11px;
    font-style: oblique;
    padding-left: 10px;
    color: var(--common-text);
    height: 43px;
    line-height: 11px;
}

._report_student_detail p {
    margin-bottom: 4px;
}

._report_students_list {
    background: var(--white);
    color: var(--common-text);
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    border: 0.5px solid var(--light-gray);
    border-bottom: none;
    padding: 0 15px;
    max-height: 86px;
    height: 43px;
}

._report_students_list_open{
    height: 86px;
}

/* LOGIN INFO*/

._report_login_info {
    width: 8%;
    float: right;
    display: inline;
    background: var(--white);
    border-right: solid 0.5px var(--light-gray);
    border-left: solid 0.5px var(--light-gray);
    border-top: solid 0.5px var(--light-gray);
}

._report_slide_control_detail {
    text-align: center;
    font-size: 12px;
    color: var(--common-text);
    background: var(--white);
    border-bottom: solid 0.5px var(--light-gray);
    font-style: italic;
    max-height: 86px;
    min-height: 43px;
}

._report_slide_control_item{
    min-height: 42px;
}

._report_slide_control_detail > button {
    height: 42px;
    padding: 0px 5px;
}

._report_slide_control_detail p {
    margin: 0;
    height: 43px;
    line-height: 42px;
}

/* CONTENT */

._report_detail_num._disable_col > svg {
    color: var(--light-gray);
}

._report_content_cols {
    scroll-behavior: smooth;
    background-size: 135px 43px;
    overflow: hidden;
    white-space: nowrap;
    width: 92%;
    display: inline-block;
    scrollbar-width: none;
    -ms-overflow-style: none;
    vertical-align: bottom;
}

._report_gray_text {
    color: var(--common-text) !important;
    border-right: solid 0.5px var(--light-gray) !important;
    -webkit-box-shadow:inset 0px 1px var(--light-gray);
    -moz-box-shadow:inset 0px 1px var(--light-gray);
    box-shadow:inset 0px 1px var(--light-gray);
    background: var(--background);
}

._report_white_text {
    color: var(--white) !important;
    background: var(--disabled);
}

._report_detail {
    height: 43px;
    line-height: 43px;
    border-right: solid 0.5px var(--white);
}

._report_class_num {
    font-size: 14px;
    color: var(--white);
}

._report_detail_num {
    font-size: 12px;
    height: 43px;
    visibility: visible;
    transition: height 0.4s;
    background: var(--white);
    border-top: 0.5px solid var(--light-gray);
    border-right: 0.5px solid var(--light-gray);
}

._report_data_content {
    margin-top: -2px;
}

._report_detail_num > span {
    margin-left: 5px;
    font-weight: normal;
}

._report_detail_num svg{
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-bottom: 3px;
}

._report_detail_num svg:not(:first-of-type)
{
    margin-left: 5px;
}

._approved_content {
    color: var(--success);
}

._approved_content_background {
    background: var(--success);
}

._not_approved_content {
    color: var(--warning);
}

._not_approved_content_background {
    background: var(--warning);
}

._pending_feedback_content{
    color: var(--info);
}

._not_started_content {
    color: var(--common-text);
}

._report_hide_detail {
    height: 0px;
    visibility: hidden;
    transition: height 0.4s;
}

._report_hide_detail > * {
    display: none;
    opacity: 0;
}

._report_gray_detail {
    color: var(--light-gray) !important;
}

._report_gray_detail span:last-child {
    color: var(--common-text) !important;
}

._report_gray_detail._disable_col svg.fa-circle,
._report_gray_detail._disable_col span:last-child {
    color: var(--light-gray) !important;
}

#_report_scroll_area {
    max-height: 430px;
}

.os-scrollbar-horizontal {
    display: none;
}

._report_blocks_content {
    display: flex;
}

._report_blocks_content > div {
    width: 50px;
    height: 43px;
    border-right: solid 0.5px var(--light-gray);
    border-bottom: solid 0.5px var(--light-gray);
    padding: 5px;
    display: flex;
    align-items: center;
    background: var(--white);

    svg{
        margin: 0 auto;
    }
}

._report_blocks_content > div:last-child {
    border-right: solid 1px var(--common-text);
}

._report_blocks_content img {
    width: 15px;
    height: 20px;
    margin: auto;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
