.welcome-header {
    align-items: center;
    background-color: var(--primary);
    border-radius: 8px;
    color: var(--background);
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    margin: 10px auto;
    padding: 10px 20px;
    width: 100%;

    &--centered {
        justify-content: center;
    }

    &__greeting {
        align-items: center;
        display: flex;

        &--text {
            font-family: var(--secondary-font);
            font-size: 24px;
            font-weight: 700;
            margin: 0 10px;
        }
    }

    &__next-class-msg {
        color: var(--background);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        line-height: 22px;
        margin: 0;
        text-align: center;

        &--text {
            font-family: var(--primary-font);
            font-size: 16px;
            margin: 0 2.5px;
            white-space: nowrap;
        }

        &--class-name {
            font-family: var(--secondary-font);
            font-size: 18px;
            font-weight: 700;
            margin: 0 5px;
        }

        &--no-lesson-link {
            justify-content: flex-end;
        }
    }

    &__link-to-class {
        align-items: center;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-name: zoom-link-glow;
        color: var(--background);
        display: flex;
        flex-direction: row;
        padding: 20px 10px;

        &--icon {
            margin-right: 10px;
        }

        &--text {
            font-family: var(--secondary-font);
            font-size: 16px;
            font-weight: 600;
            margin: 0;
        }

        &:hover {
            animation: unset;
            color: var(--background);
            cursor: pointer;
            text-decoration: underline;
        }

        &:visited,
        &:focus,
        &:active {
            color: var(--background);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }

        &--disabled {
            animation: unset;
            color: var(--disabled);
            pointer-events: none;

            &:hover,
            &:visited,
            &:focus,
            &:active {
                color: var(--disabled);
            }
        }
    }

    @media (max-width: 1200px) {
        &__next-class-msg {
            display: flex;
            flex-wrap: wrap;
            line-height: 25px;
            width: 50%;
            &--no-lesson-link {
                text-align: end;
            }
        }
    }

    @media (max-width: 992px) {
        &--centered {
            .welcome-header__greeting {
                flex-direction: row;
            }
        }
        &__greeting {
            flex-direction: column;
            height: 100%;
            justify-content: space-evenly;
            &--img {
                height: 40px;
                margin-bottom: 5px;
            }
            &--text {
                font-size: 24px;
                margin-top: 5px;
                text-align: center;
            }
        }
    }

    @media (max-width: 600px), (orientation: portrait) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 50px;
        &__greeting {
            &--text {
                font-size: 20px;
            }
        }
        &__next-class-msg {
            justify-content: center;
            margin: 10px 0;
            position: unset;
            transform: unset;
            width: 100%;
            &--no-lesson-link {
                text-align: center;
                margin-bottom: 0;
            }
        }
        &__link-to-class {
            padding: 10px;
        }
        &--centered {
            justify-content: space-evenly;
            .welcome-header__greeting {
                height: 80%;
                text-align: center;
                &--img {
                    height: 50px;
                }
                &--text {
                    font-size: 30px;
                }
            }
        }
    }
}

@keyframes zoom-link-glow {
    0% {
        color: var(--background);
    }
    25% {
        color: var(--ps-link-hover);
    }
    50% {
        color: var(--background);
    }
    75% {
        color: var(--ps-link-hover);
    }
    100% {
        color: var(--background);
    }
}
