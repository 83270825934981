.__search_table_result {
    ._start_search {
        font-size: 20px;
        color: var(--common-text);
    }

    table.__table {
        tbody {
            tr {
                td {
                    display: table-cell;
                    padding: 6px 8px;
                    height: 53px;

                    .__assigned_user {
                        padding: 6px 15px;
                        color: var(--common-text);
                        background-color: var(--background);
                        border-radius: 7px;
                        text-align: center;
                        width: 105px;
                        font-weight: 600;
                        font-size: 12px;
                        display: inline-block;
                    }

                    .__assign_user {
                        margin: auto;
                    }
                }
            }

            tr:hover {
                box-shadow: var(--common-box-shadow);
            }
        }

        thead {
            tr {
                th {
                    display: table-cell;
                    padding: 10px 8px !important;
                }
            }
        }
    }
}

.__badge_box {
    display: inline-block;

    ._badge {
        margin: 10px;
        border-radius: 40px;
        padding: 0 20px;
        cursor: pointer;
        transition: 0.2s;
        outline: none !important;
        position: relative;
        font-family: var(--secondary-font);
        background-color: var(--white);
        border: solid 1px var(--common-text);

        &__username {
            font-size: 14px;
            padding: 0;
            margin: 0;
            display: inline-block;
            color: var(--common-text);
        }

        &__cancel-icon {
            margin-left: 12px;
            display: inline-block;
            color: var(--disabled);
        }
    }
}