.student-grades {
    border-bottom: 1px solid var(--light-gray);
    padding-bottom: 60px;
    &__grades {
        display: flex;
        width: 100%;
        height: 60px;
        .slider-wrapper {
            padding: 0;
        }
    }

    &__slider {
        flex: 1;
        position: relative;
    }

    &__slider-box {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .__alert-icon {
        color: var(--warning);
    }

    &__card-assessment-container {
        margin-right: 14px;
        min-width: 200px;
    }

    &__card-assessment {
        height: 60px;
    }
}
