.welcome-empty-state {
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 70px 0;

    @media (orientation: portrait) {
        width: 80%;
    }

    &__image {
        display: block;
        margin: auto;
    }

    &__title,
    &__subtitle {
        color: var(--common-text);
        margin: 0;
    }

    &__title {
        font-family: var(--secondary-font);
        font-size: 18px;
        font-weight: 600;
        margin: 12px 0 5px;
    }

    &__subtitle {
        font-family: var(--primary-font);
        font-size: 16px;
        font-weight: 400;
    }
}