
.quiz-classroom-footer {
    height: 100px;
    background-color: var(--background);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    &__complete-text{
        font-size: 13px;
        margin: 0;
    }
}