.menu-secondary {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
}

.menu-secondary__wrapper {
  width: 100%;
  max-width: 80rem;

  & > .menu {
    box-shadow: none;

    & > .menu__container {
      width: 100%;
    }
  }
}
