.__center_buttons_course {
    padding-top: 9px;
}

.__card_title {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.42px;
    margin: 13px 0 15px 15.5%;
    color: var(--common-text);
    width: 83%;

    &.__title_margin {
        margin: 13px 0 15px 0 !important;
    }

    @media (min-width: 1500px) {
        // StudentSurvey
        margin: 13px 0 15px 13% !important;
    }
}

.dropdown--item {
    text-align: left;
    display: flex;

    &-img {
        text-align: center;
        width: 25px;
        margin-right: 10px;
    }
}

.__card {
    &.left-border {
        &--disabled {
            border-left: 2px solid var(--disabled);
        }
        &--success {
            border-left: 2px solid var(--success);
        }
        &--success-light {
            border-left: 2px solid var(--success-light);
        }
    }
}

._cursor_pointer {
    cursor: pointer;
}
