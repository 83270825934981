.__not_found {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: var(--primary-dark);
    color: var(--white);
    margin: auto;
    position: fixed;

    &__content {
        text-align: center;

        &--image {
            mix-blend-mode: lighten;
        }

        &--title {
            font-family: Poppins;
            font-weight: 700;
            font-size: 32px;
            margin-bottom: 10px;
        }

        &--subtitle {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 30px;
            font-family: Poppins;
        }

        &--buttons {
            display: flex;
            width: 100%;
            height: 40px;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;
        }
    }
}
