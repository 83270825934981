@import './variables';

.StudentUnitsContainer {
    &__grades-container {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        width: 80%;
        
        @media (orientation: portrait) {
            flex-direction: column-reverse;
            width: 100%;
            justify-items: start;
        }
    }
    &__community-button {
        top: 44px;
        position: absolute;
        z-index: 1;
        
        @media (orientation: portrait) {
            position: relative;
            top: 0;
            margin: 0 0 10px;
        }
    }

    &__community-icon {
        width: 24px;
    }

    &.container {
        padding: 16px;
        padding-top: 0;
        @media (orientation: portrait) {
            padding-top: 0px;
            margin-top: 85px;
        }
        margin-top: 20px;
    }

    .crumbs {
        .ultimo {
            text-transform: capitalize;
        }
    }

    .StudentCourseTitle {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;

        h1 {
            color: var(--common-text);
            font-size: 24px;
            font-weight: 700;
            line-height: 40px;
            margin: 0;
            padding: 30px 0 15px;
            width: 85%;
            font-family: var(--secondary-font);

            @media (orientation: portrait) {
                font-size: 22px;
                line-height: 33px;
                padding-top: 32px;
                padding-bottom: 0px;
            }
        }
    }

    .StudentUnitsGridContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 80%;
        margin-top: 20px;
        min-height: 73vh;

        @media (orientation: portrait) {
            width: 100%;
            min-height: 80vh;
        }

        .link {
            color: var(--primary);
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.3px;
            font-family: var(--secondary-font);
            letter-spacing: unset;
            &:hover {
                text-decoration: none;
                cursor: pointer;
                color: $hover-blue;
            }
            @media (orientation: portrait) {
                font-size: 14px;
            }
            &.disabled {
                color: $opacity-gray;
                pointer-events: none;
            }
        }

        .StudentUnitsHeader {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 2px 24px;
            @media (orientation: portrait) {
                padding: 0;
                border-top: unset;
                margin-bottom: 24px;
            }

            p {
                font-size: 18px;
                font-weight: 600;
                color: $gray-semi-dark;
                font-family: var(--secondary-font);
                margin-bottom: 0;
                @media (orientation: portrait) {
                    font-size: 15px;
                }
            }

            .link {
                margin-left: auto;
            }
        }

        .StudentUnitsGridCell {
            width: 100%;
            margin-bottom: 24px;
            @media (orientation: portrait) {
                margin-bottom: 16px;
            }

            .unitCardContent {
                border-radius: 10px;
                padding: 16px;
                border: 1px solid $border-card;
                background-color: $bg-card;
                border: 1px solid $opacity-lighter-gray;
                border-radius: 6px;
                box-shadow: 0 3px 6px 0 $dark-card-shadow;
                overflow: hidden;
                @media (orientation: portrait) {
                    border-radius: 12px;
                    max-height: unset;
                    min-height: 132px;
                }
                &.activeUnit {
                    border: solid 1px $gray-semi-dark;
                }

                &.colapsedContent {
                    max-height: 1000px;
                    transition: max-height 0.45s ease-in;
                }
                &.disabled {
                    .contentBody {
                        display: none;
                    }
                }

                .contentHeader {
                    @media (orientation: portrait) {
                        margin-bottom: 0;
                    }

                    .moduleInfo {
                        border-bottom: solid 1px $opacity-gray;
                        position: relative;
                        width: 100%;
                        @media (orientation: portrait) {
                            border-bottom: unset;
                            &::after {
                                content: '';
                                border-top: 1px solid $opacity-gray;
                                display: block;
                                width: 22px;
                                position: relative;
                                top: 6px;
                            }
                        }

                        p {
                            font-size: 13px;
                            color: $black-text;
                            margin-bottom: 4px;
                            text-transform: lowercase;
                            &::first-letter {
                                text-transform: capitalize;
                            }
                        }
                        span {
                            position: absolute;
                            right: 80px;
                            top: 7px;
                            display: inline-block;
                            color: $gray-semi-dark;
                            font-size: 15px;
                            font-family: var(--primary-font);
                            font-weight: light;
                            font-style: italic;
                            border: solid 1px $gray-semi-dark;
                            background-color: var(--white);
                            padding: 3px 11px;
                            border-radius: 12px;
                            @media (orientation: portrait) {
                                right: -5px;
                                top: -5px;
                                font-size: 14px;
                                padding: 2px 9px;
                            }

                            &.notReleased {
                                border-color: $opacity-gray;
                            }
                        }
                    }
                    .unitTitle {
                        display: flex;
                        justify-content: flex-start;
                        .barAndExpand {
                            width: 50%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            &.onlyExpand {
                                justify-content: flex-end;
                            }
                        }
                        @media (orientation: portrait) {
                            margin-bottom: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .progressBox {
                                margin-left: 0;
                                margin-bottom: 10px;
                            }
                        }

                        p {
                            margin-top: 16px;
                            margin-bottom: 24px;
                            margin-right: 16px;
                            color: var(--common-text);
                            text-transform: lowercase;
                            font-size: 18px;
                            font-family: var(--primary-font);
                            font-weight: 700;
                            width: 50%;
                            &::first-letter {
                                text-transform: capitalize;
                            }
                            @media (orientation: portrait) {
                                width: 100%;
                                font-size: 16px;
                                margin: 18px 0px 16px;
                            }
                        }

                        .icon {
                            font-size: 27px;
                            color: $gray-semi-dark;
                            stroke: var(--white);
                            stroke-width: 20px;
                            cursor: pointer;
                            padding: 2px 12px;
                        }

                        & + .link {
                            padding: 12px;
                            position: relative;
                            left: -12px;
                        }
                    }
                    .progressBox {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-top: 15px;
                        &.toLeft {
                            margin-right: 33%;
                        }
                        @media (max-width: 1200px) {
                            margin-right: 20%;
                        }
                        @media (min-width: 1200px) and (max-width: 1500px) {
                            &.toLeft {
                                margin-right: 27%;
                            }
                        }
                        @media (min-width: 1500px) {
                            margin-left: 25%;
                            &.toLeft {
                                margin-right: 21%;
                            }
                        }
                        .progressText {
                            padding-top: 8px;
                            font-family: var(--primary-font);
                            line-height: 18px;
                            color: var(--common-text);
                            font-size: 13px;
                        }
                    }
                }

                .contentBody {
                    transition: max-height 0.35s ease-out;
                    max-height: 0;
                    &.colapsedContent {
                        max-height: 2000px;
                        transition: max-height 0.45s ease-in;
                    }
                    .topLine::before {
                        content: '';
                        border-top: 1px solid $opacity-gray;
                        display: block;
                        width: 22px;
                        padding-bottom: 14px;
                    }

                    .aboutClass {
                        padding-top: 35px;
                        color: $gray-semi-dark;

                        p:first-child {
                            font-family: var(--primary-font);
                            font-size: 16px;
                            font-weight: 700;
                        }

                        p:last-child {
                            font-size: 15px;
                        }
                    }

                    .unitCardClasses {
                        padding-top: 3%;

                        p {
                            font-family: var(--primary-font);
                            font-weight: 700;
                            color: $gray-semi-dark;
                            font-size: 16px;
                        }

                        ul {
                            list-style-type: none;
                            padding-left: 0;

                            li {
                                padding-top: 10px;
                                border-bottom: solid $opacity-gray 1px;
                                .ko-progress-circle {
                                    margin: 0;
                                    display: inline-block;
                                    margin-bottom: -4px;
                                }
                                .titleAndDate {
                                    margin-bottom: 10px;
                                    width: 100%;
                                    display: flex;
                                    align-items: center;

                                    .nextLesson {
                                        display: inline-block;
                                        background-color: var(--success-light);
                                        color: var(--common-text);
                                        padding: 5px 10px;
                                        font-size: 15px;
                                        line-height: 21px;
                                        border-radius: 13px;
                                        margin-left: auto;
                                        margin-right: 2%;
                                        .videoIcon {
                                            height: 16px;
                                            width: 16px;
                                            vertical-align: text-bottom;
                                        }
                                        span {
                                            margin-left: 5px;
                                        }
                                    }
                                }

                                &:last-child {
                                    border-bottom: unset;
                                }
                                span:first-child {
                                    color: $gray-semi-dark;
                                    font-family: var(--primary-font);
                                    font-size: 15px;
                                    margin-right: 12px;
                                }
                                &.disabled {
                                    .link,
                                    span:first-child {
                                        color: $opacity-gray;
                                        pointer-events: none;
                                    }
                                }
                            }
                        }

                        .liveClass {
                            color: $gray-semi-dark;
                            display: inline-block;
                            padding: 0 20px;
                            font-size: 15px;
                            margin-left: 20px;
                            font-weight: 300;
                            border-left: solid 1px $gray-semi-dark;
                            font-family: var(--primary-font);
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }
}
