._modal_responsive {
    @media (orientation: portrait) {
        width: 100%;
        height: 100vh;
        margin: 0;

        .modal-dialog {
            width: 100%;
            height: 100vh;
            margin: 0;

            .modal-content {
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .modal-header {
                    margin-top: 18%;
                }
                .__modal_body {
                    height: 60vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .__modal_footer {
                    ._btn_group {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}
