._student_tab_container {
    margin-top: 40px !important;
}

.__min_card_height {
    min-height: 50px !important;
}

.StudentTab {
    p {
        a {
            color: var(--info) !important;
        }
    }

    ._student_tab_container {
        margin-top: 20px;
        @media (orientation: portrait) {
            margin-top: 0px !important;
        }
    }

    ._student_tab_container .__main_header {
        @media (orientation: portrait) {
            padding: 23px 0 29px 0;
            .__main_title {
                font-weight: 600;
                color: var(--common-text);
                letter-spacing: unset;
                font-size: 18px;
                margin-left: 0;
            }
        }
    }
}
