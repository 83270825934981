.slider-wrapper {
    padding: 20px 20px;

    @media (orientation: portrait) {
        padding: 20px 7px;
    }

    &__content {
        width: 100%;
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        cursor: initial;

        &::-webkit-scrollbar {
            display: none;
        }

        & > * {
            flex: 0 0 auto;
        }

        &__navigation {
            min-width: 60px;
            border-color: var(--primary);
            position: absolute;
            top: 50%;
                
            &-left {
                left: 5px;    
            }

            &-right {
                right: 5px;
            }

            display: none;
            color: var(--light-gray);
            pointer-events: none;
            cursor: pointer;
            vertical-align: sub;
            &:not(:last-child) {
                margin-right: 10px;
            }
            border-color: var(--light-gray);
            .button-round { 
                color: var(--primary);
                border-color: var(--primary);
                background-color: var(--white);
                &:hover {
                    background-color: var(--light-gray);
                    transition: ease-in-out .25s;
                }
            }
            &__active {
                display: flex;
                pointer-events: auto;
            }

        }
    }

    & .dragging-slider {
        cursor: grabbing;
    }

    &__footer {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 25px;

        &__indicator {
            margin-right: 15px;
            width: 100%;
            display: flex;
            flex-flow: wrap;
            &-item {
                position: relative;
                cursor: pointer;
                flex: 1;
                /* width: 37px; */
                min-width: 8px;
                max-width: 37px;
                height: 4px;
                border-radius: 8px;
                background-color: var(--light-gray);
                transition: all 0.5s;

                &:not(:last-child) {
                    margin-right: 7px;
                }

                &.__active {
                    background-color: var(--primary);
                }

                &::after {
                    position: absolute;
                    content: '';
                    background-color: transparent;
                    width: 110%;
                    padding: 10px 0;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &__navigation {
            min-width: 60px;
            &-arrow {
                color: var(--light-gray);
                pointer-events: none;
                cursor: pointer;
                vertical-align: sub;

                &:not(:last-child) {
                    margin-right: 10px;
                }

                &.__active {
                    color: var(--primary);
                    pointer-events: auto;
                }
            }
        }
    }
}
