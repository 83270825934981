.__bigger {
    height: 25px;
    width: 25px;
}

.__card_text {
    display: block;
    font-size: 14px;
    color: var(--common-text);
    margin: 8px 15px 0 15px;
}

.__successBG {
    background-color: var(--success);
}
