@import './_variables.scss';

.floating-alert-warning {
    z-index: 2000;
    width: 100%;
    position: sticky;
    top: 0px;
    left: 0px;
    margin: auto;
    padding: 16px;
    margin-bottom: 0;
    background-color: $ph-lightyellow;
    border-radius: none;
    display: flex;
    justify-content: space-between;

    @media (min-width: 800px) {
        padding: 24px 32px;
    }

    @media (min-width: 1024px) {
        padding: 24px 56px;
    }

    @media (min-width: 1366px) {
        padding: 24px 72px;
    }

    div {
        display: flex;
        width: 88%;
    }

    .exclamation_icon {
        color: $ph-warningyellow;
        font-size: 24px;
        margin-right: 8px;
    }

    div p {
        color: var(--common-text);
        font-size: 15px;
        @media (min-width: 768px) {
            font-size: 16px;
        }
    }

    Button.close_icon {
        color: var(--common-text);
        opacity: 0.7;
        font-size: 22px;
        font-weight: bold;
        border: none;
        background-color: transparent;
        text-shadow: 0 1px 0 var(--white);
        align-self: baseline;
        position: relative;
        top: -3px;

        path {
            stroke: $ph-lightyellow;
            stroke-width: 45px;
        }

        &:hover {
            color: var(--common-text);
            text-decoration: none;
            cursor: pointer;
            opacity: 0.5;
        }

        &:focus {
            outline: unset;
        }
    }

    p {
        font-size: 16px;
    }

}
