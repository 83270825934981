.__course_card {
    background-color: var(--white);
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
    padding: 0 40px 25px 40px;
    transition: 0.2s;
    margin: auto;
    margin-bottom: 30px;
    min-height: 100px;
    border: 1px solid var(--light-gray);
    position: relative;

    .__card_active {
        border-radius: 0 0 5.5px 5px;
        display: inline-block;
        padding: 5px 0px;
        text-align: center;
        top: 0;
        width: 150px;

        &__text {
            color: var(--common-text);
            display: inline;
            font-family: var(--secondary-font);
            font-size: 12px;
            letter-spacing: 0.5px;
            margin: 0;
            text-transform: uppercase;
        }

        &--green-bg {
            background-color: var(--success-light);
        }
        &--grey-bg {
            background-color: var(--primary-light);
        }
        &--orange-bg {
            background-color: var(--danger-light);
        }
    }

    &__padding {
        padding: 0 40px 7px 40px;
    }

    &__footer {
        border-top: 1.3px solid var(--light-gray);
        padding: 10px 0 0 0;
        margin-top: 15px;
        display: inline-flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

        .__course_parent {
            display: inline-block;
            top: 0;
            text-align: center;
            color: var(--disabled);

            &__text {
                font-family: var(--primary-font);
                font-size: 14px;
                display: inline;
            }
        }

        .__card_detail {
            padding: 0;
            margin: 0;
            color: var(--disabled);
            font-family: var(--primary-font);

            & > li {
                display: inline-flex;
                margin-right: 20px;
                font-size: 14px;

                &:last-child {
                    margin-right: 0;
                    margin-left: 20px;
                }
            }
        }
    }
}

.__container-flex {
    &__row {
        display: flex;
        align-content: space-between;
        align-items: self-start;
        justify-content: space-between;
        flex-direction: row;
    }

    &__first-col {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 0;

        .__card_course_type {
            padding: 6px 15px;
            color: var(--common-text);
            background-color: var(--background);
            border-radius: 7px;
            margin-top: 15px;
            text-align: center;
            width: 105px;
            font-weight: 700;
            font-size: 12px;
            display: inline-block;
        }
    }

    &__second-col {
        display: flex;
        align-content: normal;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;

        .__admin_card_title {
            display: block;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0.42px;
            color: var(--common-text);
            margin: 42px 15px 0 15px;
        }

        .__admin_card_alias {
            display: block;
            font-size: 14px;
            color: var(--common-text);
            margin: 8px 15px 0 15px;

            strong {
                margin-right: 5px;
            }
        }
    }

    &__third-col {
        display: flex;
        align-content: normal;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        width: 30%;

        ._btn_group {
            margin-top: 41px;

            ._view_course_button {
                min-width: 120px;
            }

            ._open_modal_course_button {
                margin: 0 10px 0 20px;
            }
        }
    }
}

.course-finder__institute {
    font-size:  16px;
}
