.course-card {
    &-wrapper {
        position: relative;
        width: 280px;
        background-color: var(--white);
        border: solid 1px var(--light-gray);
        border-radius: 8px;
        padding: 48px 15px 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        

        &:not(:last-child) {
            margin-right: 30px;
        }

        @media (orientation: portrait) {
            width: 275px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    &__flag {
        position: absolute;
        top: 0;
        left: 0;
        padding: 7px 14px;
        border-radius: 8px 0 8px 0;
        font-weight: 700;
        font-family: var(--primary-font);
        text-transform: uppercase;

        &.__finished {
            background-color: var(--success-light);
        }

        &.__on_going {
            background-color: var(--info-light);
        }

        &.__not_active {
            background-color: var(--warning-light);
        }
    }

    &__cover {
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 13px;

        img {
            background-color: var(--light-gray);
            object-fit: cover;
            width: 100%;
            pointer-events: none;
        }
    }

    &__name {
        height: 75px;

        p {
            font-size: 22px;
            line-height: 36px;
            font-weight: 700;
            font-family: var(--secondary-font);
            margin-top: 10px;
            margin-bottom: 12px;
            white-space: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-transform: uppercase;
            overflow-wrap: anywhere;
            width: 250px;
        }

        &--one-line {
            p {
                -webkit-line-clamp: 1;
                word-wrap: anywhere;
            }
        }
    }

    &__tooltip {
        overflow-wrap: anywhere;
    }

    &__date {
        display: flex;
        align-items: center;
        margin-bottom: 17px;
        margin-top: 14px;
        margin-left: 2.5px;

        &.__hidden {
            visibility: hidden;
        }

        &-icon {
            min-width: 24px;
            max-width: 24px;
        }

        &-info {
            font-weight: 600;
            font-size: 16px;
            color: var(--common-text);
            margin-left: 8px;
        }
    }

    &__days {
        color: var(--disabled);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        &-icon {
            display: flex;
            align-items: center;
        }
        &-text {
            font-family: var(--primary-font);
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin-left: 10px;
        }
        &.__hidden {
            visibility: hidden;
        }
    }

    &__options {
        &__dropdown {
            border: 1px solid var(--background);
            background: var(--white);
            border-radius: 8px;
            position: absolute;
            left: -128px;
            top: 38px;
            min-width: 190px;
            z-index: 1002;
            box-shadow: var(--darker-box-shadow);
            font-family: var(--primary-font);
        }

        &__button {
            font-weight: 400;
            font-size: 15px;
            display: flex;
            padding: 10px 12px;
            color: var(--common-text);
            line-height: 20px;
            width: 100%;
        }

        &__list {
            all: initial;
            position: absolute;
            right: -7px;
            top: 0;
            font-family: var(--primary-font);

            &--icon {
                color: var(--disabled);
            }
        }
    }

    &__footer {
        &__content {
            display: flex;
            padding: 10px 0 8px 0;

            &--completed,
            &--released {
                color: var(--disabled);
                margin-right: 15px;
            }

            .__released {
                fill: var(--info-light);
            }

            .__completed {
                fill: var(--info);
            }

            .__released,
            .__completed {
                stroke: transparent;
                margin-right: 5px;
                position: relative;
                top: 1px;
            }
        }
    }
}
