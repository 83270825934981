.table-presence {
    background-color: var(--danger-light);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    min-height: 34px;
    font-family: var(--primary-font);
    font-weight: bold;
    padding: 0 4px;
    &--success {
        background-color: var(--success-light);
    }
}