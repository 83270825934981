.exercise-block-container {
    background-color: var(--white);
    padding: 30px 20px;
    border-radius: 7px;
    border: 1px solid var(--light-gray);

    &:not(:first-child) {
        margin: 10px 0 0;
    }

    &:last-child{
        margin-bottom: 10px;
    }
    &__content {
        @media (orientation: portrait) {
            display: none;
        }
    }

    &__mobile-message {
        @media (orientation: landscape) {
            display: none;
        }
    }
}