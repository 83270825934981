.activity-panel-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;

    &__info--title {
        margin: 0 0 0.8rem;
        font-size: 22px;
        font-family: var(--secondary-font);
        color: var(--common-text);
        font-weight: 600;
    }

    &__info--status {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-height: 45px;

        #requestActivityRedo {
            margin-left: 1rem;
            font-weight: 700;

            [class*='__text'] {
                text-decoration: underline;
            }

            > * {
                font-size: 14px;
            }
        }
    }

    &__dropdown {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        &--text {
            margin: 0 1rem 0 0;
        }

        &__no-results {
            font-family: var(--primary-font);
            display: inline-block;
            padding: 0 24px 12px;
        }
    }
}
