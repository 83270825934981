.paymentsNotFound {
    display: flex;
    justify-content: center;
    p {
        margin-top: 27px;
        font-size: 32px;
        color: var(--disabled);
        font-weight: 700;
        font-family: var(--primary-font);
        line-height: 39px;
    }
    &--mobile {
        padding-top: 120px;
        text-align: center;
    }
}
