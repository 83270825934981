$ph-green: var(--success);


.progress-circle {
    height: 20px;
    width: 20px;

    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;

    border-radius: 50%;

    font-size: 12px;
    margin-right: 10px;
    flex: 0 0 auto;

    &--active {
        border: 3px solid var(--common-text);

        &:before {
            border: 3px solid var(--common-text);
            background-color: var(--common-text);
            content: '';
            width: 8px;
            height: 8px;
            display: block;
            position: absolute;
            border-radius: 50%;
        }
    }

    &--green {
        font-size: 20px;
        color: $ph-green;
        border-color: $ph-green;

        &__icon {
            color: $ph-green;
            border-color: $ph-green;
            font-size: 20px;
        }

        &::before {
            border: 3px solid $ph-green;
            background-color: $ph-green;
        }
    }

    &--orange {
        font-size: 20px;
        color: var(--warning);
        border-color: var(--warning);

        &__icon {
            color: var(--warning);
            border-color: var(--warning);
            font-size: 20px;
        }

        &::before {
            border: 3px solid var(--warning);
            background-color: var(--warning);
        }
    }

    &__icon {
        font-size: 20px;
    }

    &__extra {
        align-items: center;
        background-color: var(--disabled);
        border-radius: 50%;
        border: 2px solid var(--white);
        display: flex;
        height: 18px;
        justify-content: center;
        position: absolute;
        right: -9px;
        top: -9px;
        width: 18px;

        .__svg_icon {
            width: 100%;
        }
    }
}
