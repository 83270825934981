.dropdowns-row-container {
    margin-top: 50px;
    &__inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4rem;

        &--item {
            width: 23%;
        }
    }

    &--alert {
        margin-bottom: 25px;
    }
}

@media (max-width: 1100px) {
    .dropdowns-row-container {
        flex-direction: column;

        .dropdowns-row__input {
            margin-top: 10px;
            width: 100%;
        }
    }
}
