.__card_loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--rgb-black), 0.3);
    z-index: 1001;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .loadingIcon {
        color: var(--white);
        font-size: 30px;
    }
}
