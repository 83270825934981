$no-results-image-size: 400px;
$no-results-backgorund-color: var(--light-gray);
$no-results-font-color: var(--common-text);

.no-results {
    display: inline-flex;

    justify-content: center;
    flex-direction: column;

    width: $no-results-image-size;
    height: $no-results-image-size;

    border-radius: 50%;
    background-color: $no-results-backgorund-color;

    color: $no-results-font-color;

    &__text {
        padding: 15px 0;
        font-size: 17px;
        font-family: var(--secondary-font);
        opacity: 0.88;
    }

    &__image {
        opacity: .6;
    }
}
