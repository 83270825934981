.spinner_login {
    background-color: var(--white);
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -50px 0px 0px -50px;
    }
}
