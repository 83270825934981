@import '../../../../../assets/css/mixins/block-content.scss';

.blockContent {
    @include block-content;

    @media (orientation: portrait) {
        &.fileBlock .__medium_card {
            margin: auto;
            border-radius: 3px;
            min-height: unset;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.fileBlock {
    display: flex;
    flex-direction: column;
    overflow-wrap: anywhere;
    max-width: 100%;
    overflow-x: hidden;
    background: var(--white);
    margin: 10px 0 0;
    padding: 30px 20px !important;
    border-radius: 7px;
    border: 1px solid var(--light-gray);

    &:last-child {
        margin-bottom: 10px;
    }

    .__medium_card {
        transition: 0.2s;
        margin: 30px 0;
        min-height: 100px;
        border: 1px solid var(--light-gray);
        position: relative;
        background-color: var(--background);
        border-radius: 7px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 22px;

        @media (orientation: portrait) {
            border: none;
            margin: 0% 3%;
            width: 94%;
            min-height: 52vh;
            margin-top: 30px;
            margin-bottom: 30px;

            body {
                flex-direction: column;
            }
        }

        &__block-name {
            color: var(--common-text);
            font-size: 16px;
            margin: 15px 0 0 0;

            @media (orientation: portrait) {
                word-break: break-all;
                width: 90%;
                margin-top: 20px;
            }
        }
    }
}
