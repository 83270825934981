$bootstrap-danger: var(--danger);

.custom-form-field-label {
    font-family: var(--primary-font);
    display: inline-block;
}

.input-required-field {
    color: $bootstrap-danger;
    margin-left: 2px;
}

.input-error-label.help-block.help-block-sm {
    color: $bootstrap-danger;
    padding-top: 0;
    margin-top: 0;
    font-family: var(--primary-font);
    font-size: 14px;
    &::first-letter {
        text-transform: capitalize;
    }
}
