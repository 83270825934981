.container.info {
    margin-top: 20px;
    @media(orientation: portrait) {
        margin-top: 0;
    }
}

.comissionTitle {
    font-size: 24px;
    line-height: 35px;
    font-family: var(--secondary-font);
    font-weight: bold;
    color: var(--common-text);
}

.courseDescription {
    width: 50%;
    line-height: 21px;
    color: var(--disabled);
    font-size: 15px;
    margin-bottom: 72px;
    @media(max-width: 768px){
        width: 100%;
    }
}
.subtitle {
    font-size: 20px;
    line-height: 28px;
    color: var(--common-text);
    font-weight: 600;
    font-family: var(--primary-font);
    margin-top: 0;
    margin-bottom: 24px;
}

.dates {
    font-family: var(--primary-font);
    font-size: 15px;
    line-height: 21px;
    color: var(--disabled);
    margin-bottom: 33px;

}

.dateSeparator{
    margin-left: 50px;
    margin-right: 50px;
}
