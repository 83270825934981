.FileManager {

  .FileTools {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .FileFilter {
      display: flex;
      flex-direction: column;
      width: 30%;
    }

    .FileControl {
      display: flex;
      flex-direction: row-reverse;
      width: 70%;
      padding-top: 10px;
    }
  }

  .FileListGroup {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 20px;

    .FileListGroupItem {
      display: flex;
      padding: 10px 15px;
      margin-bottom: -1px;
      background-color: var(--white);
      border: 1px solid var(--light-gray);

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .FileDetails {
        width: 75%;
        display: flex;
        flex-flow: row;
        .Detail {
          padding: 0.8%;
          width: 45%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.__input_addon {
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  font-family: var(--primary-font);
  padding: 0 20px;
}

.__input_file_text {
  height: 35px;
  border-radius: 20px;
  padding: 0 20px;
  font-size: 14px;
  border-color: var(--light-gray);
  outline: none !important;
  box-shadow: none;
  color: var(--common-text);
}

.FileUploadFileModalInput {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 0 20px;
  font-size: 14px;
  border-color: var(--light-gray);
  outline: none !important;
  box-shadow: none;
  color: var(--common-text);
  height: 35px;
}

.__input_label {
    padding-left: 20px;
    font-weight: 300;
    color: var(--common-text);
}
