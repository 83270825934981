.logoutContainer .confirmMessageMobile {

    .content {
        padding: 102px 66px 60px 66px;
    }

    .icon {
        margin: auto auto 12px auto;
        width: 40px;
        height: 40px;
        color: var(--warning);
    }

    .message {
        font-family: var(--primary-font);
        line-height: 32px;
    }

    .verticalButtonGroup {

        .greenButton {
            font-size: 12px;
            line-height: 20px;
            width: 100px;
            margin-bottom: 18px;
        }

        .normalButton {
            font-size: 12px;
            line-height: 18px;
            width: 100px;
            margin-bottom: 18px;
        }
    }

}