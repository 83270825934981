.course-header {
    margin: 20px 0;

    @media (orientation: portrait) {
        padding: 65px 0;
    }

    .call-to-action {
        padding-left: 0;
    }

    &__course-info {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__title {
            color: var(--common-text);
            font-family: var(--secondary-font);
            font-size: 32px;
            font-weight: 700;
            margin: 10px 0;

            @media (orientation: portrait) {
                font-size: 18px;
                text-align: center;
            }
        }

        &__subtitle {
            align-items: center;
            display: flex;
            margin: 10px 0;

            &-tag {
                background: var(--info-light);
                border-radius: 7px 0px 8px;
                font-family: var(--primary-font);
                font-size: 16px;
                font-weight: bold;
                padding: 7px 33px;
                text-transform: uppercase;
            }

            &-name {
                color: var(--disabled);
                font-family: var(--secondary-font);
                font-size: 22px;
                font-weight: 500;
                margin-left: 13px;
            }
        }
    }
}
