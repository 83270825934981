.no-connection {
    &__block-title {
        padding: 30px 0;
    }

    &-empty-state {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0 70px;

        &__title {
            color: var(--disabled);
            font-family: var(--secondary-font);
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }

        &__img {
            margin: 25px 0;
            max-width: calc(100% / 3);
        }

        &__subtitle {
            color: var(--common-text);
            font-family: var(--primary-font);
            font-size: 16px;
            font-style: italic;
            margin-bottom: 0;
            text-align: center;
        }
    }
}
