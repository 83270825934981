.expandable-card {
    border-radius: 8px;
    border: solid 1px var(--light-gray);
    background-color: transparent;
    position: relative;
    transition: 0.5s;

    &.__marked {
        background-color: var(--white);
        cursor: default;
    }

    &__header {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        padding: 13px 20px;
        pointer-events: none;

        &--expandable {
            cursor: pointer;
            pointer-events: all;
        }

        &-title,
        &-description {
            margin-bottom: 0;
        }

        &-icon {
            position: relative;
            display: inline-flex;
            margin-right: 7px;
        }

        &-title {
            font-family: var(--secondary-font);
            color: var(--common-text);
            margin-right: 18px;
        }

        &-description {
            font-family: var(--primary-font);
            color: var(--disabled);
        }

        @media (orientation: portrait) {
            display: block;

            &-title {
                display: inline-block;
                margin-bottom: 3px;
            }

            &-icon {
                top: 5px;
            }

            &-description {
                font-size: 16px;
            }
        }
    }

    &__content {
        display: block;
        overflow: hidden;
        opacity: .7;
        max-height: 0;

        &.__enable-expand {
            transition: all .8s cubic-bezier(0, 1, 0, 1);

            &--expanded {
                opacity: 1;
                max-height: 2000px;
                transition: max-height 1s ease-in-out;
            }
        }

        &.__expanded {
            max-height: 2000px;
            opacity: 1;
        }
    }

    &__icon {
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;

        svg {
            transition: all 0.5s;
        }

        &.__expanded {
            svg {
                transform-origin: center;
                transform: rotate(180deg);
            }
        }
    }
}
