@import './_variables.scss';

.deploying-page {
    height: 100%;
    width: 100%;
    position: fixed;
    color: var(--common-text);
    display: flex;
    align-items: center;
    background: var(--white);

    &__text {
        width: 40%;
        padding: 50px;

        &--pg-logo {
            width: 180px;
            margin-bottom: 30px;
        }

        &--dh-logo {
            position: absolute;
            bottom: 60px;
            width: 120px;

            @media (max-width: 768px), (max-height: 550px) {
                position: relative;
                top: 20px;
            }
        }

        &--title {
            font-size: 32px;
            font-weight: 700;
            font-family: var(--secondary-font);
            margin-bottom: 20px;
        }

        &--message {
            font-size: 15px;
        }

        @media (max-width: 768px) {
            width: 100%;
            background: var(--white);
            border-radius: 15px 15px 0 0;
            z-index: 999;
        }
    }

    &__image {
        background-color: var(--primary-dark);
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            mix-blend-mode: lighten;
        }

        @media (max-width: 768px) {
            align-items: flex-start;
            width: 100%;

            img {
                max-width: 500px;
            }
        }
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: var(--primary-dark);
    }
}
