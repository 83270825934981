.exercise-list-no-results {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--secondary-font);
    color: var(--common-text);

    img {
        margin-bottom: 12px;
    }

    &__title {
        margin-top: 40px;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
    }

    &__subtitle {
        margin: 0;
        font-size: 15px;
    }
}